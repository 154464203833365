import { ComputedRef } from "vue";

export interface RouteAction {
  label?: string;
  icon?: string;
  iconColor?: string;
  loading?: boolean | ComputedRef<boolean>;
  disabled?: boolean | ComputedRef<boolean | number>;
  hide?: boolean | ComputedRef<boolean>;
  action(): any;
  routerName?: string;
}

export interface ModalConfirmationInfo {
  title: string;
  text: string;
  btnText: string;
  action: (props?: any) => void;
  onClose?: (props?: any) => void;
  onCancel?: (props?: any) => void;
  isDelete?: boolean;
  value?: string;
  checkbox?: {
    text: string;
    value: boolean;
  };
  confirmText?: string;
  compact?: boolean;
}

export enum Language {
  "en",
  "es",
}

export enum StateStatus {
  "Unpublished" = 0,
  "UnpublishedChanges" = 1,
  "Published" = 2,
  "Publishing" = 3,
}

export interface SearchReplaceResult {
  index: number;
  position: number;
  searchTerm: string;
}

export interface ContextmenuOption {
  action(): void;
  text?: string;
  isSeperator?: boolean;
  isDisabled?: boolean;
  icon?: {
    name: string;
    color: string;
  };
}

export interface ComponentState {
  componentName: string;
  componentId: string;
  info: any;
}
