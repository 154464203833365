import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useWebgl } from "./webgl";
import { useUtils } from "./utils";
import { useSlideUtils } from "./slide";
import { useNotification } from "./notification";
import { useAsset } from "./asset";
import { Module, ModuleMediaTypes } from "@/models/module";
import {
  ModulePresentation,
  ModulePresentationSlidePutPost,
  ModulePresentationSlide,
} from "@/models/modulePresentation";
import { ProjectAvatarVoiceTimeIndex } from "@/models/project";

export const useModulePresentation = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const utilsComposable = useUtils();
  const assetComposable = useAsset();
  const webglComposable = useWebgl();
  const slideUtilsComposable = useSlideUtils();
  const notificationComposable = useNotification();

  const { email, id } = store.state.Auth.originalInfo;

  async function get(moduleData: Module) {
    let modulePresentation: ModulePresentation;

    if (!moduleData.presentation) {
      modulePresentation = await createUpdateSlide(
        createSlideObject(moduleData)
      );
    } else {
      modulePresentation = await axios.get(endpoints.PRESENTATION.GET, {
        params: {
          presentationId: moduleData.presentation.presentationId_chr,
        },
      });
    }

    let assets: Array<{ url: string; type: ModuleMediaTypes }> = [];

    _.forEach(modulePresentation.slides, (slide) => {
      if (slide.mediaTrigger.media && slide.mediaTrigger.media.length) {
        assets = [
          ...assets,
          ..._.map(slide.mediaTrigger.media, (media) => {
            return {
              url: media.mediaURL,
              type: media.triggerType,
            };
          }),
        ];
      }
    });

    await assetComposable.getModuleAssets(assets);

    _.forEach(modulePresentation.slides, (slide) => {
      slide._layers = slideUtilsComposable.createModuleLayersObject(
        slide.slideData_chr,
        slide
      );
    });

    return modulePresentation;
  }

  function createUpdateSlide(payload: ModulePresentationSlidePutPost) {
    payload.lastUpdatedByUserId_chr = id;
    return axios
      .post(endpoints.PRESENTATION.CREATE_SLIDE, payload)
      .then((response: any) => {
        return response as ModulePresentation;
      });
  }

  function deleteSlide(
    presentationId: string,
    slideId_chr: string,
    moduleId: string
  ) {
    return axios
      .delete(endpoints.PRESENTATION.DELETE_SLIDE, {
        data: {
          lastUpdatedByUserId_chr: id,
          presentationId,
          slideId_chr,
          moduleId,
        },
      })
      .then((response: any) => {
        notificationComposable.success("presentation.delete_slide_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("presentation.delete_slide_error");
        return error;
      });
  }

  function importSlide(moduleId: string, file: File) {
    const formData = utilsComposable.makeFormData({
      moduleId,
      presentationFile: file,
      blobFolderName: assetComposable.containerName.value,
    });
    return axios.post(endpoints.PRESENTATION.IMPORT, formData);
  }

  function importSlideURL(moduleId: string, fileURL: string) {
    const formData = utilsComposable.makeFormData({
      moduleId,
      fileURL: fileURL,
      blobFolderName: assetComposable.containerName.value,
    });
    return axios
      .post(endpoints.PRESENTATION.IMPORT_URL, formData)
      .catch((error) => {
        notificationComposable.error("presentation.import_slides_error");
        return error;
      })
      .then((response) => response);
  }

  function createSlideObject(
    module: Module,
    slide?: ModulePresentationSlide,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    const groupedLayers = slide
      ? _.groupBy(_.orderBy(slide._layers, "wordIndex", "asc"), "type")
      : {};

    const slideData = slide ? slide.slideData_chr : "";

    const presentationSlideObject: ModulePresentationSlidePutPost = {
      ...{
        animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
          slideData,
          groupedLayers.animation || []
        ),
        mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
          slideData,
          groupedLayers.media || []
        ),
        pauseList: slideUtilsComposable.createPauseListObject(
          groupedLayers.pause || []
        ),
        htmlContent: slideData,
        htmlContent_Pause: slideData,
        lastUpdatedByUserId_chr: "",
        moduleId: module.moduleId,
        slideData_chr: slideData,
        slideId: slide ? slide.id : "",
        slideMediaType:
          slide && slide.mediaAttached
            ? slide.mediaAttached.mediaType_chr
            : null,
        slideMediaURL: "",
        speechLength: webglComposable.audioState.value.duration
          ? Math.ceil(webglComposable.audioState.value.duration * 1000)
          : 0,
        fullScrMedia: false,
        wordIndexes: timeIndexes ? _.map(timeIndexes, (v) => v.value) : [],
        showCme: slide ? slide.showCme : true,
      },
      ...slideUtilsComposable.createPronunciationObject(
        groupedLayers.pronunciation || {}
      ),
    };

    return presentationSlideObject;
  }

  function sortSlides(moduleData: Module, presentation: ModulePresentation) {
    return axios.put(endpoints.PRESENTATION.SORT, {
      presentationId: presentation.presentationId_chr,
      slideIds: _.map(presentation.slides, (slide) => slide.id),
      moduleId: moduleData.moduleId,
      lastUpdatedByUserId_chr: email,
    });
  }

  function revertSlideToPublished(
    presentationId: string,
    slideId: string,
    moduleId: string
  ) {
    return axios
      .post(endpoints.PRESENTATION.REVERT_SLIDE, null, {
        params: { presentationId, slideId, moduleId },
      })
      .then((response: any) => {
        notificationComposable.success("presentation.revert_slide_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("presentation.revert_slide_error");
        throw error;
      });
  }

  return {
    get,
    createUpdateSlide,
    deleteSlide,
    importSlide,
    importSlideURL,
    createSlideObject,
    sortSlides,
    revertSlideToPublished,
  };
};
