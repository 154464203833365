import _ from "lodash";
import axios from "axios";
import _apiConfig from "../../../public/api_config_default.json";

let apiConfig = _.cloneDeep(_apiConfig);

function initApiConfig() {
  return axios
    .get("/api_config.json")
    .then((response: any) => {
      apiConfig = response.data;
    })
    .catch(() => {
      console.log("Fail to get api config");
    });
}

export { initApiConfig, apiConfig };
