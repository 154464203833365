const _import = (path: string) =>
  import(`@/views/protected/profile/${path}.vue`);

export default [
  {
    path: "profile",
    name: "protected.profile",
    component: () => _import("Profile"),
    meta: {
      name: "Profile",
    },
    redirect: {
      name: "protected.profile.change-password",
    },
    children: [
      {
        path: "change-password",
        name: "protected.profile.change-password",
        component: () => _import("ChangePassword"),
        meta: {
          name: "Change Password",
        },
      },
    ],
  },
];
