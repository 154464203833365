import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useNotification } from "./notification";
import {
  Code,
  CodePost,
  CodePut,
  CodeListItem,
  CodeAnalytics,
  CodeModal,
  CodeShare,
  CodeShareModal,
  CodeShareEmail,
  CodeShareText,
  CodeByCodeNameResponse,
  CodeByProject,
  CodeByModule,
  CodeByTopic,
  CodeUpdateTags,
} from "@/models/code";
import { Tag } from "@/models/tag";
import dayjs from "dayjs";
import { BlobItem } from "@/models/asset";

export const useCode = () => {
  const store = useStore();
  const notificationComposable = useNotification();
  const endpoints = inject("endpoints") as EndpointsEnum;
  const apiConfig = inject("apiConfig") as ApiConfigEnum;

  const { clientId, clientName, id } = store.state.Auth.originalInfo;

  function getTopCodes(codesIds: Array<string>, dates?: Array<any>) {
    const _formatDate = (date: string) => dayjs(date).format("DD-MMM-YYYY");

    const filter = {
      end: dayjs().add(1, "day").format("DD-MMM-YYYY"),
      start: dayjs().subtract(7, "day").format("DD-MMM-YYYY"),
    };

    if (dates) {
      filter.end = _formatDate(dates[1]);
      filter.start = _formatDate(dates[0]);
    }

    return axios
      .post(endpoints.CODES.TOP_LIST, {
        codeids: codesIds,
        ...filter,
      })
      .then((response: any) => {
        return response["views"] as Array<CodeAnalytics>;
      });
  }

  function getRecentCodes(codesIds: Array<string>) {
    return axios
      .post(endpoints.CODES.RECENT_LIST, {
        codeids: codesIds,
        end: dayjs().add(1, "day").format("DD-MMM-YYYY"),
        start: dayjs().subtract(7, "day").format("DD-MMM-YYYY"),
      })
      .then((response: any) => {
        return response["views"] as Array<CodeAnalytics>;
      });
  }

  function createCode(codeInfo: CodePost) {
    codeInfo.createdByUserId_chr = id;
    codeInfo.clientId = clientId;
    codeInfo.uniqueCodes_num = Number(codeInfo.uniqueCodes_num);

    return axios
      .post(endpoints.CODES.CREATE, codeInfo)
      .then((response: any) => {
        notificationComposable.success("share.create_code_success");
        return response as CodeListItem;
      })
      .catch((error) => {
        if (error.response.status === 409) {
          notificationComposable.error("share.create_code_error_409");
        } else {
          notificationComposable.error("share.create_code_error");
        }
        throw error;
      });
  }

  function changeCodeStatus(codeId: string, isActive: boolean) {
    return axios
      .post(endpoints.CODES.ACTIVATE_DESACTIVATE, {
        codeId,
        isActive,
        updatedByUser: id,
      })
      .then((response: any) => {
        notificationComposable.success("share.change_status_success");
        return response as CodeListItem;
      });
  }

  function duplicateCode(projectId: string, avatarId: string) {
    return axios
      .get(endpoints.CODES.COPY, {
        params: {
          projectId,
          avatarId,
        },
      })
      .then(() => {
        notificationComposable.success("share.duplicate_code_success");
      });
  }

  function deleteCode(codeId: string) {
    return axios
      .delete(endpoints.CODES.DELETE, {
        params: {
          codeId,
        },
      })
      .then(() => {
        notificationComposable.success("share.delete_code_success");
      })
      .catch(() => {
        notificationComposable.error("share.delete_failed");
      });
  }

  function updateCodeTags(codeId: string, tags: Array<Tag>) {
    return axios
      .post(endpoints.CODES.UPDATE_TAG, {
        codeId,
        tags,
        updatedByUser: id,
      })
      .then((response: any) => {
        return response as CodeUpdateTags;
      });
  }

  function updateCodeAvatarBackground(codeInfo: CodePut) {
    const payload = {
      ...codeInfo,
      updatedByUser: id,
    };
    return axios.post(endpoints.CODES.UPDATE_CODE, payload).then(() => {
      notificationComposable.success("share.update_code_success");
    });
  }

  function updateQRCode(codeInfo: CodeModal, qrcodeUrl: string) {
    const code = generateUpdateCodeObject(codeInfo);
    code.qrcodeUrl = qrcodeUrl;
    return axios
      .post(endpoints.CODES.UPDATE_CODE, code)
      .then((response: any) => response["path"][0] as BlobItem);
  }

  function getCodeDetails(codeId: string) {
    return axios
      .get(endpoints.CODES.GET_DETAILS, {
        params: {
          codeId,
        },
      })
      .then((response: any) => response as Code);
  }

  function GetCodesByCodeName(codeName: string) {
    return axios
      .get(endpoints.CODES.GET_BY_CODE_NAME, {
        params: {
          codeName,
          userId: id,
        },
      })
      .then((response: any) => response as Array<CodeByCodeNameResponse>);
  }

  function GetCodesByModule(moduleId: string) {
    return axios
      .get(endpoints.CODES.GET_CODES_BY_MODULE, {
        params: {
          moduleId,
        },
      })
      .then((response: any) => response as Array<CodeByModule>);
  }

  function GetCodesByTopic(topicId: string) {
    return axios
      .get(endpoints.CODES.GET_CODES_BY_MODULE, {
        params: {
          topicId,
        },
      })
      .then((response: any) => response as Array<CodeByTopic>);
  }

  function generateUpdateCodeObject(codeInfo: CodeModal) {
    const codePut: CodePut = {
      codeId: codeInfo.codeId,
      newAvatarId: codeInfo.avatarId || "",
      isCustom: true,
      backgroundName: codeInfo.bakgroundName || "",
      backgroundURL: codeInfo.backgroundURL || "",
      projectDisplayName: codeInfo.projectDisplayName,
      qrcodeUrl: codeInfo.qrcodeUrl,
      forceVariantsOnly: codeInfo.forceVariantsOnly,
      additionalExtensionsCount: codeInfo.additionalExtensionsCount,
      // extension codes
    };

    return codePut;
  }

  function getAllCodes() {
    return axios
      .get(endpoints.CODES.LIST, {
        params: {
          clientId,
        },
      })
      .catch((error) => {
        throw error;
      })
      .then((response: any) => {
        return response["codes"] as Array<CodeListItem>;
      });
  }

  function getCodesByProject(projectId: string) {
    return axios
      .get(endpoints.CODES.GET_CODES_BY_PROJECT, {
        params: {
          projectId,
        },
      })
      .then((response: any) => {
        return response as Array<CodeByProject>;
      });
  }

  function openCode(codeName: string) {
    let url = `${apiConfig.WEBGL_URL}/?accessCode=${codeName}`;
    if (apiConfig.INSTANCE === "DEV") url += "%2BDEV";
    const openedWindow = window.open(url, "_blank");
    if (openedWindow) openedWindow.focus();
  }

  function shareCode(shareInfo: CodeShareModal) {
    const sharePayload: CodeShare = createCodeShareObject(shareInfo);

    return axios
      .post(endpoints.CODES.SHARE_CODE, sharePayload)
      .then((response: any) => {
        notificationComposable.success("Code shared successfully!");
        return response as Array<CodeShare>;
      })
      .catch(() => {
        notificationComposable.error("Fail to share code.");
      });
  }

  function createCodeShareObject(shareInfo: CodeShareModal): CodeShare {
    const shareInfoObject: CodeShare = {
      codeId: shareInfo.codeId,
    };

    const shareEmail: CodeShareEmail = {
      body: shareInfo.message,
      subject: shareInfo.subject,
      includeEmbed: shareInfo.sendEmbedCode,
      includeLink: shareInfo.sendLink,
      includeQRCode: shareInfo.sendQRCode,
      logoURL: shareInfo.logo,
      recipients: shareInfo.emails,
      qrcodeUrl: shareInfo.qrcodeUrl,
    };

    const shareText: CodeShareText = {
      message: shareInfo.message,
      phoneNumbers: shareInfo.phoneNumbers,
      clientName,
    };

    if (shareInfo.emails.length) {
      shareInfoObject["shareEmail"] = shareEmail;
    }

    if (shareInfo.phoneNumbers.length) {
      shareInfoObject["shareText"] = shareText;
    }

    return shareInfoObject;
  }

  return {
    getAllCodes,
    getCodeDetails,
    GetCodesByCodeName,
    GetCodesByModule,
    GetCodesByTopic,
    getTopCodes,
    getRecentCodes,
    createCode,
    changeCodeStatus,
    duplicateCode,
    deleteCode,
    updateCodeTags,
    updateCodeAvatarBackground,
    updateQRCode,
    generateUpdateCodeObject,
    getCodesByProject,
    openCode,
    shareCode,
  };
};
