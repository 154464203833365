import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { Tag } from "@/models/tag";
import {
  AiTopic,
  AiTopicRecord,
  AiTopicRecordPutPost,
  AiTopicRecordWithMarkupInfo,
  AiTopicMarkup,
  AiTopicMarkupPutPost,
  CompareRecordsResponse,
  AiTopicPineconeInstance,
  AiTopicResponse,
  AiTopicQuerySelected,
  AiTopicQuickTest,
  AiTopicLastPublish,
} from "@/models/aiTopic";
import { useRouter, useRoute } from "vue-router";
import { useNotification } from "./notification";
import { useSlideUtils } from "./slide";
import { useStore } from "vuex";
import { useAsset } from "./asset";
import { ModuleMediaTypes } from "@/models/module";
import {
  Project,
  ProjectAvatarVoiceTimeIndex,
  ProjectContentItem,
} from "@/models/project";
import { Language } from "@/models/utils";
import { useUtils } from "./utils";
import { useModule } from "./module";

export const useAiTopic = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const assetComposable = useAsset();
  const moduleComposable = useModule();
  const slideUtilsComposable = useSlideUtils();
  const notificationComposable = useNotification();

  const utilsComposable = useUtils();

  const { clientId, email, id } = store.state.Auth.originalInfo;

  function getModuleTopics(topicIds: Array<string>) {
    let endpoint = `${endpoints.TOPICS.GET_TOPICS_BY_ID}?`;

    _.forEach(topicIds, (id) => {
      endpoint += `topicIds=${id}&`;
    });

    endpoint = endpoint.substring(0, endpoint.length - 1);

    return axios
      .get(endpoint)
      .then((response: any) => response as Array<AiTopic>)
      .catch((err) => {
        notificationComposable.error("aitopics.get_module_details_error");
        throw err;
      });
  }

  function getAll(
    clientId: number | null = null,
    lang: Language | null = Language.en
  ) {
    return axios
      .get(endpoints.TOPICS.GET_ALL, {
        params: { clientId, lang },
      })
      .then((response: any) => response as Array<AiTopicResponse>)
      .catch((error) => {
        throw error;
      });
  }

  function createUpdate(name: string, tags: Array<Tag>, lang: Language) {
    return axios
      .post(endpoints.TOPICS.CREATE, {
        calling_UserID_chr: id,
        lastUpdatedByUserId: email,
        clientId: clientId,
        tags: tags,
        topicName: name,
        lang,
      })
      .then((response: any) => {
        notificationComposable.success("aitopics.create_topic_success");
        return response as AiTopic;
      });
  }

  function updateName(topicId: string, topicName: string) {
    return axios
      .put(endpoints.TOPICS.RENAME_TOPIC, {
        topicId,
        topicName,
        lastUpdatedByUserId: id,
      })
      .then((response: any) => {
        return response as AiTopic;
      });
  }

  function makeContentObject(
    topicResponse: AiTopicResponse
  ): ProjectContentItem {
    const _topic = topicResponse.topic;
    return {
      id: _topic.topicId || "",
      name: _topic.topicName || "",
      type: "AiTopic",
      tags: _topic.tags || [],
      status: _topic.status,
      lang: _topic.lang,
      createdAt: _topic.createdDateTime_dtm,
      lastUpdateAt: _topic.lastUpdatedDateTime_dtm,
      displayStatus: utilsComposable.getStatusName(_topic.status),
      extraInfo: {
        length: topicResponse.length,
        questionsCount: topicResponse.questionsCount,
        slidesCount: topicResponse.slidesCount,
        mediasCount: topicResponse.mediasCount,
        projectsCount: topicResponse.projectsCount,
        averageTrainingPhrases: topicResponse.averageTrainingPhrases,
      },
    };
  }

  function deleteTopic(topicId: string) {
    return axios
      .delete(endpoints.TOPICS.DETELE_ALL, {
        params: { topicId },
      })
      .then((response: any) => {
        notificationComposable.success("aitopics.delete_topic_success");
        return response as AiTopicRecord;
      });
  }

  function getSingle(topicId: string) {
    return axios
      .get(endpoints.TOPICS.GET, {
        params: { topicId },
      })
      .then((response: any) => response as AiTopicLastPublish);
  }

  function getRecords(recordIds: Array<string>) {
    return axios
      .post(endpoints.TOPICS.GET_RECORDS, recordIds)
      .then((response: any) => response as Array<AiTopicRecordWithMarkupInfo>)
      .catch((error) => {
        if (error.response.status === 404) {
          notificationComposable.error("aitopics.could_not_load_topic");
        }
        throw error;
      });
  }

  function goToEdit(topicId: string, projectId?: string) {
    const isFromDashboard = !!projectId;

    if (isFromDashboard) {
      return router.push({
        name: "protected.projects.project.ai_topic",
        params: {
          topicId,
          projectId: projectId,
        },
        query: {
          from: "dashboard",
        },
      });
    }

    return router.push({
      name: "protected.projects.project.ai_topic",
      params: {
        topicId,
        projectId: route.params.projectId,
      },
    });
  }

  async function getMarkup(markupId: string, answer: string) {
    const response = (await axios.get(endpoints.TOPICS.GET_MARKUP, {
      params: { markupId },
    })) as any;

    response.mediaTrigger = _.cloneDeep(response.mediaTriggers);

    delete response.mediaTriggers;
    const markup: AiTopicMarkup = response;

    const assets: Array<{ url: string; type: ModuleMediaTypes }> = _.map(
      markup.mediaTrigger.media,
      (media) => {
        return {
          url: media.mediaURL,
          type: media.triggerType,
        };
      }
    );

    await assetComposable.getModuleAssets(assets);

    markup._layers = slideUtilsComposable.createModuleLayersObject(
      answer,
      markup
    );

    return markup;
  }

  function createUpdateMarkup(markup: AiTopicMarkupPutPost, answer: string) {
    return axios
      .post(endpoints.TOPICS.PUT_POST_MARKUP, markup)
      .then((response: any) => {
        const markup: AiTopicMarkup = response;
        markup._layers = slideUtilsComposable.createModuleLayersObject(
          answer,
          markup
        );
        return markup;
      });
  }

  function createMarkupPutPostObject(
    record: AiTopicRecord,
    markup?: AiTopicMarkup,
    timeIndexes: Array<ProjectAvatarVoiceTimeIndex> = []
  ) {
    const answer = record.answer ? record.answer : "";
    const layers = markup ? markup._layers : [];
    const markupObject: AiTopicMarkupPutPost = {
      answer: answer,
      wordIndexes: timeIndexes ? _.map(timeIndexes, (v) => v.value) : [],
      fullScrMedia: markup ? markup.fullScrMedia : false,
      htmlContent: answer,
      htmlContent_Pause: answer,
      markupId: markup ? markup.markupId : null,
      topicId: record.topicId,
      recordId: record.recordId,
      animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
        answer,
        layers
      ),
      mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
        answer,
        layers
      ),
      pauseList: slideUtilsComposable.createPauseListObject(layers),
      ...slideUtilsComposable.createPronunciationObject(layers),
    };
    return markupObject;
  }

  function deleteRecord(recordId: string, topicId: string) {
    return axios
      .delete(endpoints.TOPICS.DELETE_RECORD, {
        params: { recordId, topicId, lastUpdatedByUserId: id },
      })
      .then((response: any) => {
        notificationComposable.success("aitopics.delete_record_success");
        return response as AiTopicRecord;
      })
      .catch((error: any) => {
        notificationComposable.error("aitopics.delete_record_error");
        notificationComposable.error(error);
        return;
      });
  }

  function exportTopic(topicId: string, topicName: string) {
    return axios
      .get(endpoints.TOPICS.EXPORT_TOPIC, {
        params: { topicId },
      })
      .then((response: any) => {
        utilsComposable.downloadJSON(response, topicName);
        return response;
      });
  }

  function createUpdateRecord(record: AiTopicRecordPutPost) {
    return axios
      .post(
        `${endpoints.TOPICS.PUT_POST_RECORD}?topicId=${record.topicId}`,
        record
      )
      .then((response: any) => {
        if (!record.recordId) {
          notificationComposable.success("aitopics.create_record_success");
        }
        return response as AiTopicRecord;
      });
  }

  function createRecordPutPostObject(topic: AiTopic, record?: AiTopicRecord) {
    const recordObject: AiTopicRecordPutPost = {
      recordId: record ? record.recordId : null,
      answer: record && record.answer ? record.answer : "",
      question: record && record.question ? record.question : "",
      topicId: topic.topicId || "",
      tags: record && record.tags ? record.tags : [],
      secondaryQuestions:
        record && record.secondaryQuestions ? record.secondaryQuestions : [],
      showCme: record ? record.showCme : true,
      indicator: record ? record.indicator : 0,
      showInFallbackMenu: record ? record.showInFallbackMenu : true,
    };
    return recordObject;
  }

  function publishTopic(topicId: string) {
    return axios
      .post(endpoints.TOPICS.PUBLISH, null, {
        params: { topicId },
      })
      .then((response: any) => {
        notificationComposable.success("notification.publish_module_success");
        // publishListenerComposable.set("aitopic", topicId);
        return response;
      })
      .catch((error) => {
        notificationComposable.error("notification.publish_error");
        throw error;
      });
  }

  function revertTopicToPublished(topicId: string) {
    return axios
      .post(endpoints.TOPICS.REVERT_TO_PUBLISHED, null, {
        params: { topicId },
      })
      .then((response: any) => {
        notificationComposable.success("aitopics.topic_revert_success");
        return response as AiTopic;
      })
      .catch((error) => {
        notificationComposable.error("aitopics.topic_revert_error");
        throw error;
      });
  }

  function saveCopy(topicId: string, clientId: number, clientName: string) {
    return axios
      .get(endpoints.TOPICS.COPY_TOPIC, {
        params: { topicId, clientId, clientName },
      })
      .then(() => {
        notificationComposable.success("aitopics.topic_copied_success");
      })
      .catch(() => {
        notificationComposable.error("aitopics.topic_copied_error");
      });
  }

  function importCSVSpreadsheet(topicId: string, file: File) {
    const formData = utilsComposable.makeFormData({
      file,
    });
    return axios
      .post(endpoints.TOPICS.IMPORT_CSV, formData, {
        params: {
          topicId,
          userId: id,
        },
      })
      .then(() => {
        notificationComposable.success("aitopics.import_spreadsheed_step_4");
      })
      .catch(() => {
        notificationComposable.error("aitopics.import_error");
      });
  }

  function importFromSpreadsheet(topicId: string, spreadsheetId: string) {
    return axios
      .post(endpoints.TOPICS.IMPORT_FROM_SPREADSHEET, {
        spreadsheetId,
        topicId,
        lastUpdatedByUserId: id,
      })
      .then(async () => {
        const topic = await getSingle(topicId);
        return topic.topic;
      });
  }

  function compareRecords(
    topicId: string,
    pineconeInstance: AiTopicPineconeInstance
  ) {
    return axios
      .get(endpoints.TOPICS.COMPARE_RECORDS_WITH_PINECONE, {
        params: {
          topicId,
          pineconeInstance,
        },
      })
      .then((response: any) => {
        if (response.similarRecords && response.similarRecords.length) {
          return response as CompareRecordsResponse;
        } else {
          return { similarRecords: [] } as CompareRecordsResponse;
        }
      });
  }

  function queryTopic(
    topicIds: Array<string | null>,
    text: string,
    pineconeInstance: AiTopicPineconeInstance
  ) {
    return axios
      .post(endpoints.TOPICS.QUERY_TOPIC, {
        topicIds,
        text,
        topK: 5,
        pineconeInstance,
      })
      .then((response: any) => {
        return response as Array<AiTopicQuerySelected>;
      })
      .catch((error) => {
        notificationComposable.error("aitopics.topic_query_failed");
        throw error;
      });
  }

  function requestTest(topicId: string, pineconeInstance: string) {
    return axios
      .post(endpoints.TOPICS.REQUEST_TEST, null, {
        params: {
          topicId,
          userId: id,
          pineconeInstance,
        },
      })
      .then((response: any) => {
        notificationComposable.success("aitopics.test_requested_successfuly");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("aitopics.test_requested_fail");
        throw error;
      });
  }

  function getProjectsByTopicId(topicId: string) {
    return axios
      .get(endpoints.TOPICS.GET_PROJECTS_BY_TOPIC_ID, {
        params: { topicId },
      })
      .then((response: any) => {
        return response as Array<Project>;
      });
  }

  async function getTopicAndRecordList(
    moduleId: string,
    isMultiple = false
  ): Promise<AiTopicQuickTest> {
    if (!isMultiple) {
      const topic = (await getSingle(moduleId)).topic;
      const recordList = await getRecords(
        _.map(topic.records, (r) => r.recordId)
      );
      return { topic, recordList };
    }

    let allRecordsIds: Array<string> = [];

    const module = await moduleComposable.get(moduleId);
    const filterTopics = _.filter(module.topics, (t) => t.topicId !== null);
    const topics = await getModuleTopics(_.map(filterTopics, (t) => t.topicId));
    if (!topics.length) {
      throw notificationComposable.error("project.module_dont_contain_topics");
    }
    const topicRecords = _.map(topics, (t) => {
      return _.map(t.records, (r) => r.recordId);
    });

    _.forEach(topicRecords, (tr: any) => {
      if (tr && tr.length) {
        allRecordsIds = allRecordsIds.concat(tr);
      }
    });

    const recordList = await getRecords(allRecordsIds);
    return { topics, recordList };
  }

  function updateRecordIndex(
    recordId: string,
    newIndex: number,
    topicId?: string | null
  ) {
    return axios
      .post(endpoints.TOPICS.UPDATE_RECORD_INDEX, null, {
        params: {
          recordId,
          newIndex,
          topicId,
        },
      })
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  return {
    getModuleTopics,
    createUpdate,
    getAll,
    getSingle,
    getRecords,
    goToEdit,
    getMarkup,
    createUpdateMarkup,
    createMarkupPutPostObject,
    createUpdateRecord,
    createRecordPutPostObject,
    deleteRecord,
    publishTopic,
    revertTopicToPublished,
    saveCopy,
    deleteTopic,
    updateName,
    compareRecords,
    importFromSpreadsheet,
    importCSVSpreadsheet,
    queryTopic,
    requestTest,
    getProjectsByTopicId,
    getTopicAndRecordList,
    exportTopic,
    makeContentObject,
    updateRecordIndex,
  };
};
