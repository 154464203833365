module.exports = {
  colors: {
    primary: "#1f859e",
    quiz: "#3da631",
    topic: "#fa8904",
    intake: "#ad1c96",
    presentation: "#0475e2",
    tagUser: "#0475E2",
    tagStatus: "#00AD26",
    tagSentiment: "#887EFF",
    red22: "#f7afaf",
    red47: "#eb5757",
    pink27: "#E361EE",
    blue43: "#3b82f6",
    blue58: "#1d4ed8",
    blue22: "#9FC2F7",
    blue45: "#2D9CDB",
    blue61: "#1f859e87",
    blue63: "#0A37D7",
    green9: "#EBF7D6",
    green26: "#aaddaf",
    green65: "#219653",
    green60: "#27AE60",
    green53: "#00FF6C",
    amber46: "#f59e0b",
    orange43: "#FF8E27",
    yellow16: "#ffe69a",
    yellow32: "#f2c94c",
    transparent: "transparent",
    white: "white",
    black: "black",
    gray4: "#f3f4f6",
    gray9: "#e5e7eb",
    gray13: "#dddddd",
    gray16: "#d1d5db",
    gray35: "#9ca3af",
    gray54: "#6b7280",
    gray74: "#374151",
    gray79: "#e9e9e9",
    analyticsDonutCenter: "white",
    analyticsCounter: "white",
    analyticsGray: "#f1f1f1",
    analyticsGreen: "#aaddaf",
    analyticsRed: "#eb5757",
    analyticsYellow: "#ffdf0f",
    previewButton: "#6BA755",
    seccondary: "#e7faff",
    moduleStatusRed: "#ffcaca",
    moduleStatusYellow: "#fffdca",
    moduleStatusWhite: "white",
    sidebar: "#fafbfc",
    headerShadow: "rgba(200,200,200,0.25)",
    "b-app": "white",
    "b-base": "white",
    "t-base": "black",
    "modal-backdrop": "#9ca3af",
  },
  dark: {
    moduleStatusRed: "#522f2f",
    moduleStatusYellow: "#393320",
    moduleStatusWhite: "#404040",
    green9: "#35431b",
    gray4: "#2e2e2e",
    gray9: "#333333",
    gray13: "#2e2e2e",
    gray16: "#606060",
    gray35: "#797979",
    gray54: "#9f9f9f",
    gray74: "#c9c9c9",
    gray79: "#363636",
    blue58: "#7c9eff",
    analyticsDonutCenter: "#2e2e2e",
    analyticsCounter: "#2e2e2e",
    analyticsGray: "#363636",
    analyticsGreen: "#97db8c",
    analyticsRed: "#eb5757",
    analyticsYellow: "#ffe69a",
    seccondary: "#333c3e",
    sidebar: "#32393E",
    headerShadow: "rgba(50,50,50,0.25)",
    "b-app": "#32393E",
    "b-base": "#404040",
    "t-base": "#f3f4f6",
    "modal-backdrop": "black",
  },
};
