import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useUtils } from "./utils";
import { useNotification } from "./notification";
import { ClientListItem, ClientPutPost, ClientRole } from "@/models/client";
import { UserForm, UserListItem } from "@/models/user";

export const useAdministration = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const utilsComposable = useUtils();
  const notificationComposable = useNotification();

  function getRoles(clientId: number) {
    return axios
      .get(endpoints.ROLES.LIST, {
        params: {
          clientId,
        },
      })
      .then((response: any) => response as Array<ClientRole>);
  }

  function getUserById(userId: string) {
    return axios
      .get(endpoints.USERS.USER_INFO, {
        params: {
          id: userId,
        },
      })
      .then((response: any) => response as UserForm);
  }

  function getAllUsers() {
    return axios.get(endpoints.USERS.LIST_ALL).then((response: any) => {
      response.map(function (obj: any) {
        return obj as UserListItem;
      });
    });
  }

  function createUser(userInfo: UserForm) {
    return axios
      .post(endpoints.USERS.ADD, userInfo)
      .then((response) => {
        notificationComposable.success("admin.create_user_success");
        return response;
      })
      .catch((error) => {
        if (!error.response.data) {
          notificationComposable.error("admin.create_user_error");
        } else {
          notificationComposable.error(error.response.data);
        }
        throw error;
      });
  }

  function updateUserInformation(userInfo: object) {
    return axios
      .put(endpoints.USERS.UPDATE_INFO, userInfo)
      .then((response) => {
        notificationComposable.success("admin.update_user_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("admin.update_user_error");
        throw error;
      });
  }

  function sendUserResetPassword(userId: any) {
    const formData = utilsComposable.makeFormData({ id: userId });
    return axios
      .post(endpoints.USERS.RESET_PASSWORD, formData)
      .then((response: any) => {
        const message = response.message;
        notificationComposable.success(message);
        return response;
      })
      .catch((error) => {
        notificationComposable.error(error);
        throw error;
      });
  }

  function deleteUser(userId: string) {
    return axios
      .delete(`${endpoints.USERS.DELETE}?id=${userId}`)
      .then((response: any) => {
        notificationComposable.success(response);
        return response;
      })
      .catch((error) => {
        notificationComposable.error(error);
        throw error;
      });
  }

  function getClients() {
    return axios
      .get(endpoints.CLIENTS.LIST, {
        params: {
          Calling_UserID_chr: store.state.Auth.info.id,
        },
      })
      .then((response) => response);
  }

  function getClientById(clientId?: string) {
    return axios
      .get(endpoints.CLIENTS.LIST, {
        params: {
          Calling_UserID_chr: store.state.Auth.info.id,
          ClientID_lng: clientId || store.state.Auth.info.clientID,
        },
      })
      .then((response: any) => {
        return response.tblClient[0] as ClientListItem;
      });
  }

  function createClient(clientName: string) {
    return axios
      .post(endpoints.CLIENTS.POST_PUT, {
        ClientName_chr: clientName,
        Calling_UserID_chr: "",
      })
      .then((response) => {
        notificationComposable.success("admin.create_client_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("admin.create_client_error");
        throw error;
      });
  }

  function updateClientInformation(clientInfo: ClientPutPost) {
    return axios
      .post(endpoints.CLIENTS.POST_PUT, clientInfo)
      .then((response) => {
        notificationComposable.success("admin.update_client_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("admin.update_client_error");
        throw error;
      });
  }

  function deleteClient(clientId: any) {
    return axios
      .delete(endpoints.CLIENTS.DELETE, {
        params: {
          Calling_UserID_chr: store.state.Auth.info.id,
          ClientID_lng: clientId,
          is_Hard_Delete_ysn: false,
        },
      })
      .then((response) => {
        notificationComposable.success("admin.delete_client_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error(error);
        throw error;
      });
  }

  return {
    getClients,
    getRoles,
    getUserById,
    createUser,
    updateUserInformation,
    sendUserResetPassword,
    deleteUser,
    getClientById,
    createClient,
    updateClientInformation,
    deleteClient,
    getAllUsers,
  };
};
