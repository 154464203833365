const availableLangs = ["es", "en"];

async function importLang(lang: string) {
  const pages = await import(`./translations/${lang}/pages.json`);
  const shared = await import(`./translations/${lang}/shared.json`);
  const header = await import(`./translations/${lang}/header.json`);
  const module = await import(`./translations/${lang}/module.json`);
  const component = await import(`./translations/${lang}/component.json`);
  const termsOfService = await import(
    `./translations/${lang}/termsOfService.json`
  );

  const translations = {
    ...{ header },
    ...{ pages },
    ...{ component },
    ...{ shared },
    ...{ module },
    ...{ termsOfService },
  } as any;

  const obj = {} as any;

  for (const key in translations) {
    for (const translation in translations[key]) {
      obj[translation] = translations[key][translation];
    }
  }

  return obj;
}

async function getTranlations() {
  let i = 0;

  const allTranslations: any = {};

  const loop = async () => {
    const lang = availableLangs[i];
    if (!lang) return allTranslations;
    allTranslations[lang] = await importLang(lang);
    i++;
    await loop();
  };
  await loop();

  return allTranslations;
}

export default { getTranlations };
