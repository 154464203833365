const _import = (path: string) =>
  import(`@/views/protected/aiTools/${path}.vue`);

export default [
  {
    path: "ai-tools/:topicId?",
    name: "protected.ai-tools",
    component: () => _import("AiTools"),
    meta: {
      name: "ai-tools.base",
    },
    redirect: {
      name: "protected.ai-tools.dashboard",
    },
    children: [
      {
        path: "",
        name: "protected.ai-tools.dashboard",
        component: () => _import("Dashboard"),
        meta: {
          name: "ai-tools.base",
        },
      },
      {
        path: "test/:testId",
        name: "protected.ai-tools.test",
        component: () => _import("Test"),
        meta: {
          name: "ai-tools.request_test",
        },
      },
      {
        path: "quick-test",
        name: "protected.ai-tools.quick-test",
        component: () => _import("QuickTest"),
        meta: {
          name: "ai-tools.base",
          onlyRouteContent: true,
          hideHeader: true,
        },
      },
    ],
  },
];
