export default {
  NOTIFICATION: "event.notification",
  CONFIRMATION: "event.confirmation",
  CLOSE_CONFIRMATION: "event.close-confirmation",
  CODE_INFORMATION: "event.code-information",
  UPDATE_ROUTE_ACTIONS: "event.update-route-actions",
  REFRESH_PROJECT: "event.refresh-project-page",
  REFRESH_AVAILABLE_MODULES_LIST: "event.refresh-available-modules-list",
  REFRESH_MODULES_TIMELINE: "event.refresh-modules-timeline",
  REFRESH_SINGLE_MODULE: "event.refresh-single-module",
  REFRESH_SELECTED_SLIDE: "event.refresh-selected-slide",
  REFRESH_PUBLISH: "event.refresh-module-publish",
  WEBGL_SHOW: "event.webgl-show",
  WEBGL_HIDE: "event.webgl-hide",
  WEBGL_POST: "event.webgl-post",
  WEBGL_RESIZE: "event.webgl-resize",
  WEBGL_UPDATE_TIME: "event.webgl-update-time",
  REVERT_MODULE_TO_PUBLISHED: "event.revert-module-to-published",
  REVERT_MODULE_TO_UNPUBLISHED: "event.revert-module-to-unpublished",
  SET_BREADCRUMB_PARAM: "event.set-breadcrumb-param",
  TIMELINE_CALC_GENERAL_VALUES: "event.calc-general-values",
  TEXT_TO_SPEECH_INITIALIZED: "event.text-to-speech-initialized",
  TEXT_TO_SPEECH_RENDER_LAYERS: "event.text-to-speech-render-layers",
  TEXT_TO_SPEECH_SLIDE_READY: "event.text-to-speech-slide-ready",
  SHOW_CONTEXT_MENU: "event.show-context-menu",
  SHOW_CONTENT_CONTEXT: "event.content-item:context-menu",
  CHANGE_OPENING_TYPE_QUERY: "event.change-opening-type-query-test",
  MODULE_PUBLISHED: "event.module-published",
  PROJECT_PUBLISHED: "event.project-published",
  AITOPIC_PUBLISHED: "event.aitopic-published",
  ADD_MODULE_TIMELINE: "event.add-module-timeline",
  REMOVE_MODULE_TIMELINE: "event.remove-module-timeline",
  MOVE_MODULE_TIMELINE: "event.move-module-timeline",
  SHOW_LOADER_TOPIC: "event.show-loader-topic",
  HIDE_LOADER_TOPIC: "event.hide-loader-topic",
  ADD_SECONDARY_QUESTION: "event.add-secondary-question",
  ADDED_SECONDARY_QUESTION: "event-added-secondary-question",
  UPDATE_TITLE_HEADER: "event.update-title-header",
};
