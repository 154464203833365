import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  assets: {
    uploads: {
      total: 0,
      list: [],
      fullyLoaded: false,
      blobToken: null,
      loading: false,
    },
    videos: {
      total: 0,
      list: [],
      fullyLoaded: false,
      currentPage: 0,
      loading: false,
    },
    slides: {
      total: 0,
      list: [],
      fullyLoaded: false,
      blobToken: null,
      loading: false,
    },
    defaults: {
      image: null,
      video: null,
    },
    blobToken: "",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
