import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-expanded"]
const _hoisted_2 = { id: "collapse" }

import { ref } from "vue";


const __default__ = {
  name: "app-accordion",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const isOpen = ref(true);

const toggleAccordion = () => {
  isOpen.value = !isOpen.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleAccordion())),
      "aria-expanded": isOpen.value,
      "aria-controls": "collapse",
      class: "accordion-header"
    }, [
      _renderSlot(_ctx.$slots, "title"),
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(["colappse-arrow", {
        'rotate-180': isOpen.value,
        'rotate-0': !isOpen.value,
      }]),
        fill: "none",
        stroke: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 10",
        "aria-hidden": "true"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("path", {
          d: "M15 1.2l-7 7-7-7",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, -1)
      ]), 2))
    ], 8, _hoisted_1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content")
    ], 512), [
      [_vShow, isOpen.value]
    ])
  ], 64))
}
}

})