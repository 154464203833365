import _ from "lodash";
import { ProjectContentItem } from "@/models/project";

export default {
  setContent(
    state: any,
    payload: { type: string; contents: Array<ProjectContentItem> }
  ) {
    state[payload.type] = payload.contents;
  },
  clearState(state: any) {
    _.forEach(Object.keys(state), (key: string) => {
      state[key] = [];
    });
  },
};
