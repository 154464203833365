import _ from "lodash";
import { inject } from "vue";
import { useEvent } from "./event";
import {
  RouteAction,
  ModalConfirmationInfo,
  SearchReplaceResult,
  StateStatus,
  Language,
} from "@/models/utils";
import { useNotification } from "./notification";
import { ProjectContentDisplayStatus } from "@/models/project";

export const useUtils = () => {
  const dayjs = inject("dayjs") as DayjsEnum;
  const events = inject("events") as EventsEnum;
  const constants = inject("constants") as ConstantsEnum;
  const t = inject("translation") as any;

  const eventComposable = useEvent();
  const notificationComposable = useNotification();

  function updateRouteActions(
    actions: Array<RouteAction>,
    notRemoveActionFrom: null | string = null,
    customBack: (() => void) | null = null
  ) {
    setTimeout(() => {
      eventComposable.emit(events.UPDATE_ROUTE_ACTIONS, {
        actions,
        notRemoveActionFrom,
        customBack,
      });
    }, 100);
  }

  function makeFormData(obj: any): any {
    const params = new FormData();
    Object.keys(obj).map((Key) => {
      params.append(Key, obj[Key]);
    });
    return params;
  }

  function confirm(obj: ModalConfirmationInfo) {
    eventComposable.emit(events.CONFIRMATION, obj);
  }

  function closeConfirm() {
    eventComposable.emit(events.CLOSE_CONFIRMATION);
  }

  function isEqual(array1: Array<any>, array2: Array<any>) {
    return (
      Object.keys(array1).length === Object.keys(array2).length &&
      Object.keys(array1).every((item: any) => array1[item] === array2[item])
    );
  }

  function formatSeconds(seconds: number, format = "HH:mm:ss") {
    if (seconds === undefined) seconds = 0;
    const oneMinuteAgo = dayjs().subtract(
      Math.round(seconds) * 1000,
      "millisecond"
    );
    const dur = dayjs.duration(dayjs().diff(oneMinuteAgo));
    return dayjs.utc(dur.asMilliseconds()).format(format);
  }

  function selectFile(types?: Array<string>, extensions?: Array<string>) {
    const promise = new Promise((resolve, reject) => {
      const mimeTypes = Object.values(types || constants.MIME_TYPES);
      let accept = "";
      _.forEach(mimeTypes, (type) => (accept += `${type},`));
      accept = accept.slice(0, -1);

      let input = document.createElement("input") as any;
      if (input) {
        input.type = "file";
        input.accept = accept;
        input.classList.add("file-input");
        document.body.appendChild(input);
        input.click();
        input.onchange = (e: any) => {
          const file = input.files[0];

          let isValidType = false;

          if (file.type) {
            isValidType = mimeTypes.includes(file.type);
          } else if (extensions) {
            const splitName = file.name.split(".");
            isValidType = extensions.includes(splitName[splitName.length - 1]);
          }

          if (!isValidType) {
            notificationComposable.error("media_gallery.file_not_supported");
            deleteFileInput();
            reject();
          } else if (file.size > constants.MAX_FILE_SIZE) {
            notificationComposable.error(
              "media_gallery.file_exceeded_maximum_limit"
            );
            deleteFileInput();
            reject();
          } else {
            const file: File = e.target.files[0];
            resolve(file);
          }
          document.body.removeChild(input);
          deleteFileInput();
          input = null;
        };
      }
    });
    return promise.then((file) => file as File);
  }

  function deleteFileInput() {
    const fileInput = document.querySelector(".file-input");
    if (fileInput) {
      document.body.removeChild(fileInput);
    }
  }

  function getParentScrollEl(childEl: HTMLElement): HTMLElement | undefined {
    const parentEl = childEl.parentElement;
    if (!parentEl) return;
    const style = getComputedStyle(parentEl);
    const isOverflowAuto = (val: string) => val == "auto" || val == "scroll";
    const isScrollabe =
      parentEl.scrollHeight > parentEl.offsetHeight &&
      (isOverflowAuto(style.overflow) || isOverflowAuto(style.overflowX));

    if (isScrollabe || parentEl.classList.contains("modal__content")) {
      return parentEl;
    } else {
      return getParentScrollEl(parentEl);
    }
  }

  function checkElIsFromEspecificParent(
    parentClass: Array<string>,
    el: HTMLElement
  ): boolean {
    const parentEl = el.parentElement;
    if (!parentEl) {
      return false;
    } else {
      let hasClass = false;
      for (let i = 0; i < parentClass.length; i++) {
        hasClass = parentEl.classList.contains(parentClass[i]);
        if (hasClass) break;
      }
      if (hasClass) {
        return true;
      } else {
        return checkElIsFromEspecificParent(parentClass, parentEl);
      }
    }
  }

  function setBreadcrumbParam(params: object) {
    setTimeout(() => {
      eventComposable.emit(events.SET_BREADCRUMB_PARAM, params);
    }, 100);
  }

  function replaceTextSpaces(text: string) {
    return text.replace(/\u00a0/g, " ").replace(/\xA0/g, " ");
  }

  function isUrl(value: string) {
    const regex =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regex.test(value);
  }

  function isEmail(value: string) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  }

  function formatTimer(_time: string) {
    let time = _time.split(":");
    time = _.forEach(time, (value, index) => {
      const number = Number(value);
      if (number === 0) return;
      time[index] = number.toFixed();
      if (number < 9) time[index] = "0" + number.toFixed();
    });
    return time.toString().replaceAll(",", ":");
  }

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    notificationComposable.success("tooltip.copied_clipboard");
  }

  function formatSearchHTML(
    text: string,
    searchResults: SearchReplaceResult[],
    index: number,
    activeSearchResult: SearchReplaceResult | undefined
  ) {
    let html = text;

    if (searchResults == null) return html;
    let offset = 0;
    searchResults.forEach((element: SearchReplaceResult) => {
      if (element.index == index) {
        let highlightClass = "highlight";
        if (element == activeSearchResult) highlightClass = "highlight-active";
        const markHTML = `<mark class=${highlightClass}></mark>`;

        const position = element.position;
        const prefix = html.slice(0, position + offset);
        const match = html.slice(
          position + offset,
          position + offset + element.searchTerm.length
        );
        const suffix = html.slice(
          position + offset + element.searchTerm.length
        );
        html = `${prefix}<mark class=${highlightClass}>${match}</mark>${suffix}`;
        offset = offset + markHTML.length;
      }
    });

    return html;
  }

  function formatEndpointWithQueryParams(
    endpoint: string,
    values: Array<any>,
    key: string
  ) {
    let endpointWithParams = `${endpoint}?`;
    _.forEach(values, (id) => {
      endpointWithParams += `${key}=${id}&`;
    });
    return endpointWithParams.substring(0, endpointWithParams.length - 1);
  }

  function leavePageConfirmation(action?: () => void) {
    return new Promise((resolve, reject) => {
      confirm({
        title: "Leave the page?",
        text: "Changes you made may not be saved.",
        btnText: "Leave",
        action: async () => {
          if (action) await action();
          resolve(true);
        },
        onCancel: () => reject(),
        compact: true,
      });
    });
  }

  function getPctColor(pct: number) {
    const style = getComputedStyle(document.body);
    if (pct < constants.ANALYTICS.LOW_THRESHOLD) {
      return style.getPropertyValue("--analyticsRed");
    } else if (pct < constants.ANALYTICS.HIGH_THRESHOLD) {
      return style.getPropertyValue("--analyticsYellow");
    } else {
      return style.getPropertyValue("--analyticsGreen");
    }
  }

  function getStatusName(status: number) {
    switch (status) {
      case 0:
        return "Unpublished";
      case 1:
        return "Unpublished Changes";
      case 2:
        return "Published";
      case 3:
        return "Publishing";
      default:
        return t("base.no_information");
    }
  }

  function getStatusColorByStatus(status: StateStatus) {
    let color: ProjectContentDisplayStatus;

    switch (status) {
      case 3:
      case 1:
        color = "Yellow";
        break;
      case 0:
        color = "Red";
        break;
      default:
        color = "white";
        break;
    }

    return color;
  }

  function getModuleTypeByEnum(module: number) {
    switch (module) {
      case 0:
        return "Presentation";
      case 1:
        return "Quiz";
      case 2:
        return "QnA";
      case 3:
        return "Intake";
      case 4:
        return "Navigation";
      case 5:
        return "Speech";
    }
  }

  /**
   * Downloads json data as a file
   * @param data Data to download
   * @param filename Name of the file to download (Don't include .json)
   */
  function downloadJSON(data: any, filename: string) {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(data));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  function getLanguageString(lang: number | Language) {
    switch (lang) {
      case 0:
        return "en";
      case 1:
        return "es";
      default:
        return "en";
    }
  }

  return {
    updateRouteActions,
    formatSeconds,
    makeFormData,
    confirm,
    closeConfirm,
    isEqual,
    selectFile,
    getParentScrollEl,
    setBreadcrumbParam,
    replaceTextSpaces,
    isUrl,
    isEmail,
    formatTimer,
    copyToClipboard,
    formatSearchHTML,
    checkElIsFromEspecificParent,
    formatEndpointWithQueryParams,
    leavePageConfirmation,
    getPctColor,
    getStatusName,
    getStatusColorByStatus,
    getModuleTypeByEnum,
    downloadJSON,
    getLanguageString,
  };
};
