export default [
  {
    name: "btn",
    component: "theme/Button",
  },
  {
    name: "card",
    component: "theme/Card",
  },
  {
    name: "input",
    component: "theme/Input",
  },
  {
    name: "textarea",
    component: "theme/Textarea",
  },
  {
    name: "switch",
    component: "theme/Switch",
  },
  {
    name: "menu",
    component: "theme/Menu",
  },
  {
    name: "modal",
    component: "theme/Modal",
  },
  {
    name: "confirmation-modal",
    component: "theme/ConfirmationModal",
  },
  {
    name: "icon",
    component: "theme/IconBase",
  },
  {
    name: "datatable",
    component: "theme/Datatable",
  },
  {
    name: "notification",
    component: "theme/Notification",
  },
  {
    name: "select",
    component: "theme/Select",
  },
  {
    name: "progress-circular",
    component: "theme/ProgressCircular",
  },
  {
    name: "progress-linear",
    component: "theme/ProgressLinear",
  },
  {
    name: "checkbox",
    component: "theme/Checkbox",
  },
  {
    name: "header",
    component: "layout/Header",
  },
  {
    name: "sidebar",
    component: "layout/Sidebar",
  },
  {
    name: "footer",
    component: "layout/Footer",
  },
  {
    name: "content-header",
    component: "layout/ContentHeader",
  },
  {
    name: "autocomplete",
    component: "theme/Autocomplete",
  },
  {
    name: "chip",
    component: "theme/Chip",
  },
  {
    name: "list-item",
    component: "theme/ListItem",
  },
  {
    name: "tags",
    component: "theme/Tags",
  },
  {
    name: "upload",
    component: "theme/Upload",
  },
  {
    name: "radio",
    component: "theme/Radio",
  },
  {
    name: "tooltip",
    component: "theme/Tooltip",
  },
  {
    name: "hexagon-icon",
    component: "theme/HexagonIcon",
  },
  {
    name: "vertical-stepper",
    component: "theme/VerticalStepper",
  },
  {
    name: "filter",
    component: "theme/Filter",
  },
  {
    name: "context-menu",
    component: "theme/ContextMenu",
  },
  {
    name: "dropdown",
    component: "theme/Dropdown",
  },
];
