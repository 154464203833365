import { useEvent } from "./event";
import { inject } from "vue";
import { useLocalNotifications } from "@/composables/notificationsLocal";
interface ButtonAction {
  action(): void;
  label: string;
}

export const useNotification = () => {
  const events = inject("events") as EventsEnum;
  const eventComposable = useEvent();

  function info(message: string, button?: ButtonAction) {
    eventComposable.emit(events.NOTIFICATION, {
      message,
      button,
      type: "info",
    });
    useLocalNotifications().saveNotification(message, "info");
  }

  function error(message: string, button?: ButtonAction) {
    eventComposable.emit(events.NOTIFICATION, {
      message,
      button,
      type: "error",
    });
    useLocalNotifications().saveNotification(message, "error");
  }

  function success(message: string, button?: ButtonAction) {
    eventComposable.emit(events.NOTIFICATION, {
      message,
      button,
      type: "success",
    });
    useLocalNotifications().saveNotification(message, "success");
  }

  function warning(message: string, button?: ButtonAction) {
    eventComposable.emit(events.NOTIFICATION, {
      message,
      button,
      type: "warning",
    });
    useLocalNotifications().saveNotification(message, "warning");
  }

  return {
    info,
    error,
    success,
    warning,
  };
};
