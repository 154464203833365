const _import = (path: string) => import(`@/views/auth/${path}.vue`);

export default [
  {
    path: "/auth",
    name: "auth",
    component: () => _import("Auth"),
    redirect: { name: "auth.login" },
    children: [
      {
        path: "login",
        name: "auth.login",
        component: () => _import("login/Login"),
        meta: {
          name: "Login",
        },
      },
      {
        path: "forgot-password",
        name: "auth.forgot_password",
        component: () => _import("forgotPassword/ForgotPassword"),
        meta: {
          name: "Forgot Password",
        },
      },
      {
        path: "reset-password",
        name: "auth.reset_password",
        component: () => _import("resetPassword/ResetPassword"),
        meta: {
          name: "Reset Password",
        },
      },
      {
        path: "verify-user",
        name: "auth.verify-user",
        component: () => _import("resetPassword/ResetPassword"),
        meta: {
          name: "Verify User",
        },
      },
    ],
  },
];
