import _ from "lodash";
import axios from "axios";
import { useNotification } from "./notification";
import { useStorage } from "./storage";
import { inject } from "vue";
import { useEvent } from "./event";
import router from "@/router";
import { useComponentState } from "./componentState";
import { AiTopicLastPublish } from "@/models/aiTopic";

export type PublishTypes = "project" | "module" | "aitopic";

const allPublishTypes: Array<PublishTypes> = ["project", "module", "aitopic"];
const intervalTime = 5000;
const interval: { [_type: string]: NodeJS.Timer } = {};
const timeout: { [_type: string]: NodeJS.Timeout } = {};
const publishing: {
  [_type: string]: Array<string>;
} = {
  project: [],
  module: [],
  aitopic: [],
};

export const usePublishListener = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const events = inject("events") as EventsEnum;
  const constants = inject("constants") as ConstantsEnum;

  const eventComposable = useEvent();
  const storageService = useStorage();
  const notificationComposable = useNotification();
  const componentStateComposable = useComponentState();

  function init() {
    const storageListener = storageService.get("publish-listener");
    if (!storageListener) return;
    _.forEach(storageListener, (value, key) => {
      if (key in publishing) publishing[key] = value;
    });
    startListener();
  }

  function set(_type: PublishTypes, id: string) {
    if (!publishing[_type]) publishing[_type] = [];
    publishing[_type].push(id);
    storageService.set("publish-listener", publishing);
    stopListener([_type]);
    startListener([_type]);
  }

  function isPublishing(_type: PublishTypes, id: string) {
    return publishing[_type].includes(id);
  }

  function stopListener(_types: Array<PublishTypes> = allPublishTypes) {
    _.forEach(_types, (_type) => {
      if (interval[_type]) {
        clearTimeout(timeout[_type]);
        delete interval[_type];
      }
    });
  }

  function startListener(_types: Array<PublishTypes> = allPublishTypes) {
    _.forEach(_types, (_type) => {
      if (!publishing[_type] || !publishing[_type].length) {
        stopListener([_type]);
        return;
      }

      interval[_type] = setInterval(async () => {
        const toRemove: Array<string> = [];

        for (let i = 0; i < publishing[_type].length; i++) {
          const id = publishing[_type][i];
          try {
            let isPublishing = false;
            if (_type === "module") {
              isPublishing = await checkModuleIsPublished(id);
            } else if (_type == "project") {
              isPublishing = await checkProjectIsPublished(id);
            } else if (_type == "aitopic") {
              isPublishing = await checkAiTopicIsPublished(id);
            }
            if (!isPublishing) toRemove.push(id);
          } catch (error) {
            toRemove.push(id);
          }
        }

        publishing[_type] = _.filter(
          publishing[_type],
          (pid) => !toRemove.includes(pid)
        );

        storageService.set("publish-listener", publishing);

        if (!publishing[_type].length) stopListener([_type]);
      }, intervalTime);
      timeout[_type] = setTimeout(() => null, intervalTime);
    });
  }

  function checkModuleIsPublished(moduleId: string) {
    return axios
      .get(endpoints.MODULES.GET, {
        params: {
          moduleId,
        },
      })
      .then((resp: any) => {
        const isPublished = resp.status == "Published";
        const isPublishing = resp.status == "Publishing";
        if (!isPublishing) {
          sendNotification("module", resp.moduleName, moduleId, isPublished);
        }
        return isPublishing;
      });
  }

  function checkProjectIsPublished(projectId: string) {
    return axios
      .get(endpoints.PROJECTS.GET, { params: { projectId } })
      .then((resp: any) => {
        const isPublished = resp.project.status == "Published";
        const isPublishing = resp.project.status == "Publishing";
        if (!isPublishing) {
          sendNotification(
            "project",
            resp.project.projectName,
            projectId,
            isPublished
          );
        }
        return isPublishing;
      });
  }

  function checkAiTopicIsPublished(topicId: string) {
    return axios
      .get(endpoints.TOPICS.GET, {
        params: { topicId },
      })
      .then((resp: any) => {
        const response = resp as AiTopicLastPublish;
        const isPublished =
          response.topic.status == constants.STATE_STATUS.PUBLISHED;
        const isPublishing =
          response.topic.status == constants.STATE_STATUS.PUBLISHING;
        if (!isPublishing) {
          sendNotification(
            "aitopic",
            response.topic.topicName,
            topicId,
            isPublished
          );
        }
        return isPublishing;
      });
  }

  function sendNotification(
    type: PublishTypes,
    name: string | null,
    id: string,
    isSuccess: boolean
  ) {
    const typeLabel = type === "aitopic" ? "AI Topic" : type;

    if (!isSuccess) {
      notificationComposable.error(
        `Fail to publish ${typeLabel}, ${name}, please try again.`
      );
      return;
    }

    let eventName = events.PROJECT_PUBLISHED;
    let routeName = "protected.projects.project";

    if (type === "module") {
      eventName = events.MODULE_PUBLISHED;
      routeName = "protected.projects.project.module";
    } else if (type === "aitopic") {
      eventName = events.AITOPIC_PUBLISHED;
      routeName = "protected.projects.project.ai_topic";
    }

    notificationComposable.success(
      `The ${typeLabel}, ${name} has been published successfuly.`,
      {
        action: () => {
          let lastProjectId = "none";

          const projectPageInfo = componentStateComposable.get(
            "project-page",
            "ProjectPage"
          );

          if (projectPageInfo && projectPageInfo.info.recentProjectId) {
            lastProjectId = projectPageInfo.info.recentProjectId;
          }

          const params: { [key: string]: string } = {
            projectId: type === "project" ? id : lastProjectId,
          };

          if (["module", "aitopic"].includes(type)) {
            params[type === "module" ? "moduleId" : "topicId"] = id;
          }

          router.push({
            name: routeName,
            params,
          });
        },
        label: "Open",
      }
    );

    eventComposable.emit(eventName, id);
  }

  return {
    set,
    init,
    isPublishing,
    stopListener,
  };
};
