import axios from "axios";
import { useStore } from "vuex";
import { inject } from "vue";
import { useStorage } from "./storage";
import { useNotification } from "./notification";
import { useRouter } from "vue-router";
import {
  AuthUser,
  AuthUserImpersonate,
  AuthUserInfo,
  ClientAccessListItem,
  AuthChangePassword,
  ConfirmEmail,
  ForgotPassword,
  GetUser,
  GetServiceStatus,
} from "@/models/auth";
import { useUtils } from "./utils";
import { useProject } from "./project";

export const useAuth = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const router = useRouter();
  const utilsComposable = useUtils();
  const storageComposable = useStorage();
  const notificationComposable = useNotification();
  const projectComposable = useProject();

  const { id } = store.state.Auth.info;

  function init() {
    axios.interceptors.response.use(
      (response) => response.data || response,
      (err) => {
        if (err & err.response && err.response.code === 401) {
          logout();
        }
        throw err;
      }
    );

    const token = storageComposable.get("token");
    if (token) setToken(token);

    const impersonate = storageComposable.get("impersonate");
    if (
      !!impersonate &&
      !!impersonate.type &&
      !!impersonate.id &&
      !!impersonate.originalId
    ) {
      setImpersonated(impersonate);
    }
  }

  function login(email: string, password: string): any {
    return axios
      .post(endpoints.AUTH.LOGIN, { email, password })
      .then((response: any) => {
        const loginInfo = response as AuthUser;
        setToken(loginInfo.token);
        return getUserInfo();
      })
      .catch((error) => {
        notificationComposable.error("auth.invalid_email_or_password");
        throw error;
      });
  }
  function GetUsers() {
    return axios.get(endpoints.AUTH.USERS).then((response: any) => {
      return response as GetUser;
    });
  }

  function logout() {
    delete axios.defaults.headers.common.authorization;
    setImpersonated();
    clearAuthInfo();
    router.push({ name: "auth" });
  }

  function setToken(token: string) {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
    store.commit("Auth/setToken", token);
    storageComposable.set("token", token);
  }

  function setImpersonated(
    obj: {
      type: string | null;
      id: string | number | null;
      originalId: string | number | null;
      token: string | null;
      clientName: string | null;
    } = {
      type: null,
      id: null,
      originalId: null,
      token: null,
      clientName: null,
    }
  ) {
    store.commit("Asset/clearCache");
    store.commit("Auth/setImpersonated", obj);
    storageComposable.set("impersonate", obj);
    if (obj.token) {
      axios.defaults.headers.common["authorization"] = `Bearer ${obj.token}`;
    }
    projectComposable.clearCachedContents();
  }

  async function getUserInfo() {
    try {
      const userInfo: AuthUserInfo = await axios.get(endpoints.AUTH.USER_INFO);
      store.commit("Auth/setInfo", userInfo);
      store.commit("Auth/setOriginalInfo", userInfo);
      storageComposable.set("user-info", userInfo);

      const clientAccess: Array<ClientAccessListItem> = await axios.get(
        endpoints.USER_ACCESS.GET_USER_CLIENT_LIST,
        { params: { userId: userInfo.id } }
      );
      store.commit("Auth/setClientAccess", clientAccess);
      storageComposable.set("client-access", clientAccess);

      if (userInfo && store.state.Auth.impersonate.type) {
        if (store.state.Auth.impersonate.type === "client") {
          const clientId = store.state.Auth.impersonate.id;
          const clientName = store.state.Auth.impersonate.clientName;
          userInfo.clientId = clientId;
          userInfo.clientName = clientName;
          store.commit("Auth/setClientId", userInfo.clientId);
          store.commit("Auth/setClientName", userInfo.clientName);
        } else {
          const originalUserInfo = (await axios.get(endpoints.AUTH.USER_INFO, {
            headers: {
              authorization: `Bearer ${store.state.Auth.token}`,
            },
          })) as AuthUserInfo;
          store.commit("Auth/setOriginalInfo", originalUserInfo);
        }
      }
      return userInfo;
    } catch (error) {
      clearAuthInfo();
      return error;
    }
  }

  function clearAuthInfo() {
    storageComposable.remove("token");
    storageComposable.remove("user-info");
    storageComposable.remove("impersonate");
    storageComposable.remove("client-access");
    store.commit("Auth/setToken", "");
    store.commit("Auth/setInfo", {});
  }

  function impersonateUser(userId: any) {
    if (store.state.Auth.impersonate.type === "client") {
      setImpersonated();
    }

    return axios
      .get(`${endpoints.AUTH.IMPERSONATE_USER}?userId=${userId}`)
      .then((response: any) => {
        const inpersonateUser = response as AuthUserImpersonate;
        const originalUserId =
          store.state.Auth.impersonate.type === "user"
            ? store.state.Auth.impersonate.originalId
            : store.state.Auth.info.id;
        setImpersonated({
          type: "user",
          id: userId,
          originalId: originalUserId,
          token: inpersonateUser.token,
          clientName: null,
        });
        return getUserInfo();
      })
      .catch((error) => {
        notificationComposable.error("auth.impersonate_user_error");
        throw error;
      });
  }

  function revertImpersonationUser() {
    return axios
      .get(endpoints.AUTH.REVERT_IMPERSONATION)
      .then((response: any) => {
        const inpersonateUser = response as AuthUserImpersonate;
        setToken(inpersonateUser.token);
        setImpersonated();
        return getUserInfo();
      })
      .catch((error) => {
        notificationComposable.error("auth.revert_impersonate_error");
        throw error;
      })
      .finally(() => {
        router.push({ name: "protected.dashboard" });
      });
  }

  async function impersonateClient(clientId: number, clientName: string) {
    if (store.state.Auth.impersonate.type === "user") {
      await revertImpersonationUser();
    }

    const originalClientId =
      store.state.Auth.impersonate.type === "client"
        ? store.state.Auth.impersonate.originalId
        : store.state.Auth.info.clientId;

    setImpersonated({
      type: "client",
      id: clientId,
      originalId: originalClientId,
      token: null,
      clientName,
    });

    store.commit("Auth/setClientId", clientId);
    store.commit("Auth/setClientName", clientName);
  }

  function revertImpersonationClient() {
    setImpersonated();
    return getUserInfo();
  }

  function revertImpersonation() {
    if (!store.state.Auth.impersonate.type) return;
    if (store.state.Auth.impersonate.type === "user") {
      return revertImpersonationUser();
    } else {
      return revertImpersonationClient();
    }
  }

  function forgotPassword(email: string) {
    return axios
      .post(endpoints.AUTH.FORGOT_PASSWORD, { email })
      .then((response: any) => {
        notificationComposable.success("auth.forgot_password_success");
        return response as ForgotPassword;
      })
      .catch((error) => {
        notificationComposable.error("auth.forgot_password_error");
        throw error;
      });
  }

  function resetPassword(email: string, password: string, code: string) {
    return axios
      .post(endpoints.AUTH.RESET_PASSWORD, {
        resetEmail: email,
        resetPassword: password,
        resetConfirmPassword: password,
        code,
      })
      .then((response) => {
        notificationComposable.success("auth.reset_password_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("auth.reset_password_error");
        notificationComposable.error(error.response.data);
        throw error;
      });
  }

  function changePassword(
    confirmPassword: string,
    currentPassword: string,
    newPassword: string
  ) {
    return axios
      .post(endpoints.AUTH.CHANGE_PASSWORD, {
        currentPassword,
        newPassword,
        confirmPassword,
        userId: id,
      })
      .then((response: any) => {
        notificationComposable.success("auth.change_password_success");
        return response as AuthChangePassword;
      })
      .catch((error) => {
        notificationComposable.error("auth.change_password_error");
        if (
          error.response &&
          error.response.data &&
          !error.response.data.includes("System")
        ) {
          notificationComposable.error(error.response.data);
        }
        throw error;
      });
  }
  function ConfirmEmail(code: string, userId: string) {
    return axios
      .get(endpoints.AUTH.CONFIRM_EMAIL, { params: { userId, code } })
      .then((response: any) => {
        return response as ConfirmEmail;
      });
  }

  function grantUserClientAccess(userId: string, clientIds: Array<number>) {
    const endpoint = utilsComposable.formatEndpointWithQueryParams(
      endpoints.USER_ACCESS.GRANT_CLIENT_ACCESS,
      clientIds,
      "clientIds"
    );

    return axios
      .put(endpoint, null, {
        params: { userId, clientIds },
      })
      .then((response) => {
        notificationComposable.success("auth.client_access_updated");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("auth.failed_to_update_client_access");
        throw error;
      });
  }

  function revokeUserClientAccess(userId: string, clientIds: Array<number>) {
    const endpoint = utilsComposable.formatEndpointWithQueryParams(
      endpoints.USER_ACCESS.REVOKE_CLIENT_ACCESS,
      clientIds,
      "clientIds"
    );

    return axios
      .delete(endpoint, {
        params: { userId, clientIds },
      })
      .then((response) => {
        notificationComposable.success("auth.client_access_revoked");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("auth.failed_to_update_client_access");
        throw error;
      });
  }

  function setUserAgreement() {
    return axios
      .post(endpoints.AUTH.SET_USER_AGREEMENT, { userId: id })
      .then(() => {
        store.commit("Auth/setUserAgreement");
      });
  }

  function GetServiceStatus(Include_Deleted_Clients: boolean) {
    return axios
      .get(endpoints.AUTH.GET_SERVICE_STATUS, {
        params: {
          Calling_UserID_chr: store.state.Auth.info.id,
          Include_Deleted_Clients_byt: Include_Deleted_Clients,
        },
      })
      .then((response: any) => response as GetServiceStatus);
  }

  function addUserDetail(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    phoneNumber: string,
    role: string
  ) {
    return axios
      .post(endpoints.AUTH.ADD_USER_DETAIL, {
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        phoneNumber: phoneNumber,
        role: role,
      })
      .then(() => {
        notificationComposable.success("auth.add_user_detail");
      });
  }

  return {
    init,
    login,
    logout,
    impersonateUser,
    impersonateClient,
    revertImpersonation,
    getUserInfo,
    forgotPassword,
    resetPassword,
    changePassword,
    grantUserClientAccess,
    revokeUserClientAccess,
    setUserAgreement,
    ConfirmEmail,
    GetUsers,
    addUserDetail,
    GetServiceStatus,
  };
};
