import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  presentation: [],
  aitopic: [],
  quiz: [],
  intake: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
