import { inject } from "vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import QRCode from "qrcode";
import _ from "lodash";
import JSZip from "jszip";

export const usePdf = () => {
  const apiConfig = inject("apiConfig") as ApiConfigEnum;

  async function generatePdf(
    codeName: string,
    codeExtensions: Array<string>,
    clientName = "",
    forceVariants = false
  ) {
    const zip = new JSZip();
    const QRCodes: Array<string> = [];

    //Generate QRCodes
    if (codeExtensions && codeExtensions.length > 0) {
      for (let i = 0; i < codeExtensions.length; i++) {
        const codeExtension = codeExtensions[i].split(".")[1];
        const link = await generateQRCode(codeName, codeExtension);
        QRCodes.push(link);
      }

      //Show default QRCode
      if (!forceVariants) {
        const link = await generateQRCode(codeName);
        QRCodes.push(link);
      }
    } else {
      const link = await generateQRCode(codeName);
      QRCodes.push(link);
    }

    const pdfs = await processQRCodes(
      QRCodes,
      codeExtensions,
      clientName,
      codeName
    );

    await Promise.all(
      pdfs.map(async (pdf, index) => {
        zip.file(
          `${
            codeExtensions && codeExtensions.length && codeExtensions[index]
              ? codeExtensions[index]
              : codeName
          }.pdf`,
          pdf
        );
      })
    );

    if (pdfs.length === 1 && !codeExtensions.length) {
      // Download single pdf
      const blob = pdfs[0];
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = codeName;
      document.body.append(link);
      link.click();
      link.remove();
      return;
    }

    return zip.generateAsync({ type: "blob" }).then((content: any) => {
      const zipFile = new Blob([content]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipFile);
      link.download = `${codeName}.zip`;
      link.click();
    });
  }

  async function processQRCodes(
    QRCodes: Array<string>,
    codeExtensions: Array<string>,
    clientName: string,
    codeName: string
  ) {
    const pdfPromises: Array<any> = [];

    QRCodes.forEach((url, index) => {
      const codeExtension =
        codeExtensions && codeExtensions.length && codeExtensions[index]
          ? codeExtensions[index].split(".")[1]
          : null;
      const pdfPromise = generateDocPDF(
        url,
        codeExtension,
        clientName,
        codeName
      ).then((docPDF) => docPDF.output("blob"));
      pdfPromises.push(pdfPromise);
    });

    const pdfs = await Promise.all(pdfPromises);
    return pdfs;
  }

  async function generateQRCode(
    codeName: string,
    extensionCode?: string | null
  ) {
    let param = `?accessCode=${codeName}`;
    if (extensionCode) {
      param += `.${extensionCode}`;
    }
    const link = await QRCode.toDataURL(
      `${apiConfig.WEBGL_URL}/${param}&ref=QR`
    ).then((url: string) => {
      return url;
    });
    return link;
  }

  async function generateDocPDF(
    qrCodeUrl: string,
    extensionCode: string | null,
    clientName: string,
    codeName: string
  ) {
    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const center = pageWidth / 2;

    //Logo
    const logoImg = new Image();
    logoImg.src = require("@/assets/images/HIA_Topics.png");
    doc.addImage(logoImg, "png", 15, 10, 100, 13);

    //Welcome Text
    const welcomeText =
      "Hello! You are receiving this from " +
      `${clientName}` +
      ". They have created content to help answer your questions and provide you with more information. You can access this content by opening the following URL on any browser, whether on a computer or mobile device:";
    const splitWelcome = doc.splitTextToSize(welcomeText, 230);
    doc.setFontSize(12);
    doc.text(splitWelcome, 15, 40);

    //App Text
    const appText =
      "In the App, you can use this code to access the content recommended for you:";
    const splitApp = doc.splitTextToSize(appText, 230);
    doc.setFontSize(12);
    doc.text(splitApp, 15, 70);

    //Link
    const accessText = "Access link:";
    const accessApp = doc.splitTextToSize(accessText, 230);
    doc.setFontSize(12);
    doc.text(accessApp, 15, 85);

    const codeLink = `${apiConfig.WEBGL_URL}/?accessCode=${codeName}${
      extensionCode ? `.${extensionCode}` : ""
    }`;
    doc.setFontSize(9);
    doc.setTextColor(0, 0, 238);
    doc.textWithLink(codeLink, 15, 90, {
      url: codeLink,
    });

    //IFrame
    doc.setTextColor(0, 0, 0);
    const iFrameTitle = "iFrame:";
    const iFrameTitleText = doc.splitTextToSize(iFrameTitle, 230);
    doc.setFontSize(12);
    doc.text(iFrameTitleText, 15, 100);

    const iFrameText = `<iframe url="${
      apiConfig.WEBGL_URL
    }/?accessCode=${codeName}${
      extensionCode ? `.${extensionCode}` : ""
    }" allow="microphone;autoplay" />`;
    const iFrameContent = doc.splitTextToSize(iFrameText, 250);
    doc.setFontSize(9);
    doc.text(iFrameContent, 15, 105);

    //QRCode
    const qrCodeImg = new Image();
    qrCodeImg.src = qrCodeUrl;
    doc.addImage(qrCodeImg, "jpg", center - 64 / 2, 120, 64, 64);

    //Code
    const codeText = extensionCode
      ? `${codeName}.${extensionCode}`
      : `${codeName}`;
    doc.setFontSize(16);
    doc.text(codeText, center - doc.getTextWidth(codeText) / 2, 190);

    return doc;
  }

  function generateReceipt(
    codes: Array<{
      code: string;
      email: string;
      phone: string;
    }>,
    codeName: string
  ) {
    const doc = new jsPDF("p", "mm", "a4");
    const img = new Image();
    const uniqueCode = codeName;

    const data = [] as Array<Array<string>>;
    _.forEach(codes, (code) => {
      data.push(Object.values(code));
    });

    img.src = require("@/assets/images/HIA_Topics.png");
    doc.addImage(img, "png", 15, 30, 100, 13);
    doc.setFontSize(20);
    doc.setFontSize(12);
    doc.setTextColor(99);

    autoTable(doc, {
      head: [["Code", "Email", "Phone"]],
      body: data,
      theme: "grid",
      margin: {
        top: 60,
      },
      headStyles: {
        fillColor: "#1f859e",
      },
    });

    doc.save("codeReceipt-" + uniqueCode + ".pdf");
  }

  return {
    generatePdf,
    generateReceipt,
  };
};
