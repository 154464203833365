import Admin from "./Admin";
import AiTools from "./AiTools";
import Profile from "./Profile";
import Projects from "./Projects";
import Share from "./Share";
const _import = (path: string) => import(`@/views/protected/${path}.vue`);

export default [
  {
    path: "/",
    name: "protected",
    component: () => _import("Protected"),
    redirect: {
      name: "protected.dashboard",
    },
    meta: {
      name: "dashboard.base",
      hidePadding: false,
    },
    children: [
      ...Admin,
      ...Projects,
      ...Profile,
      ...AiTools,
      ...Share,
      {
        path: "dashboard",
        name: "protected.dashboard",
        component: () => _import("dashboard/Dashboard"),
        meta: {
          name: "dashboard.home",
        },
      },
      {
        path: "track",
        name: "protected.track",
        component: () => _import("track/Track"),
        meta: {
          name: "track.base",
        },
        redirect: {
          name: "protected.track.dashboard",
        },
        children: [
          {
            path: "",
            name: "protected.track.dashboard",
            component: () => _import("track/Dashboard"),
            meta: {
              name: "track.base",
            },
          },
          {
            path: "code/:codeName/:type/:id?",
            name: "protected.track.codeAnalyticts",
            component: () => _import("track/CodeAnalytics"),
            meta: {
              name: "track.details",
            },
          },
        ],
      },
      {
        path: "messages",
        name: "protected.messages",
        component: () => _import("messages/Messages"),
        meta: {
          name: "messages.base",
        },
      },
    ],
  },
];
