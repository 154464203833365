import _ from "lodash";
import { Module } from "@/models/module";

export default {
  setModule(state: any, payload: { type: string; modules: Array<Module> }) {
    state[payload.type] = payload.modules;
  },
  clearState(state: any) {
    _.forEach(Object.keys(state), (key: string) => {
      state[key] = [];
    });
  },
};
