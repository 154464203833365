import _ from "lodash";
import { useField } from "vee-validate";
import AllRules from "@vee-validate/rules";
import { v4 as uuidv4 } from "uuid";

export const useInputValidation = (
  _rules: Array<any>,
  _name: string,
  _value: any
) => {
  const rules: any = {};

  _.forEach(_rules, (rule: string) => {
    if (rule.includes("|")) {
      const ruleSplit = rule.split("|");
      rules[ruleSplit[0]] = ruleSplit[1];
    } else if (rule.includes("@")) {
      const ruleSplit = rule.split("@");
      rules[ruleSplit[0]] = `@${ruleSplit[1]}`;
    } else {
      rules[rule] = AllRules[rule];
    }
  });

  const { value: inputValue, errorMessage } = useField(
    _name || uuidv4(),
    rules,
    {
      initialValue: _value,
      validateOnMount: false,
      validateOnValueUpdate: true,
    }
  );

  return {
    inputValue,
    errorMessage,
  };
};
