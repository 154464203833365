import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../../assets/images/chrome.png'
import _imports_1 from '../../../assets/images/firefox.png'


const _hoisted_1 = { class: "webgl__box" }
const _hoisted_2 = {
  key: 0,
  class: "webgl__cover"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "webgl__cover"
}
const _hoisted_5 = {
  key: 2,
  class: "webgl__cover"
}
const _hoisted_6 = ["src"]

import _ from "lodash";
import { inject, onMounted, ref, computed } from "vue";
import { useEvent, useWebgl } from "@/composables";
import { WebglMessagePost } from "@/models/webgl";
import { useRoute } from "vue-router";


const __default__ = {
  name: "webgl-component",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const events = inject("events") as EventsEnum;
const apiConfig = inject("apiConfig") as ApiConfigEnum;

const eventComposable = useEvent();
const webglComposable = useWebgl();
const route = useRoute();

let elementId = "module-preview-box";

const scrollTop = ref<number>(0);
const iframeEl = ref<HTMLIFrameElement>();
const active = ref(false);
const style = ref({
  top: "0px",
  left: "0px",
  width: "1024px",
  height: "768px",
  zIndex: 30,
});

const iframeStyle = ref({
  width: "1024px",
  height: "768px",
});

const iframeUrl = ref(`${apiConfig.WEBGL_URL}/?dynamicMode=true`);

const initialized = computed(() => webglComposable.initialized.value);
const agentLoaded = computed(() => webglComposable.agentLoaded.value);
const loadingError = ref(false);
const computedStyle = computed(() => {
  const _style = _.cloneDeep(style.value);
  _style.top = `${+_style.top.replace("px", "") - scrollTop.value}px`;
  return _style;
});

function setStyle() {
  const box = document.getElementById(elementId);
  if (!box || !box.firstChild) {
    setTimeout(() => setStyle());
    return;
  }

  const positionEl = box.firstChild as HTMLElement;
  style.value.left = `${positionEl.offsetLeft}px`;
  style.value.top = `${positionEl.offsetTop}px`;
  style.value.width = `${box.offsetWidth}px`;
  style.value.height = `${box.offsetHeight}px`;

  const scale = Math.min(box.offsetWidth / 1280, box.offsetHeight / 720);
  iframeStyle.value.width = `${1280 * scale - 20}px`;
  iframeStyle.value.height = `${720 * scale - 20}px`;
}

function show(obj: { divId: undefined | string; zIndex: undefined | number }) {
  const routes = _.map(route.matched, (r) => r.name);

  if (
    !routes.includes("protected.dashboard") &&
    !routes.includes("protected.projects.project.module") &&
    !routes.includes("protected.projects.project.ai_topic")
  ) {
    return;
  }

  style.value.zIndex = obj.zIndex || 30;
  elementId = obj.divId || "module-preview-box";
  setTimeout(() => {
    setStyle();
    active.value = true;
    toggleScrollEvt();
  });
  checkAgentLoaded();
}

function hide() {
  active.value = false;
  toggleScrollEvt(true);
}

function postMessage(message: WebglMessagePost) {
  if (iframeEl.value && iframeEl.value.contentWindow) {
    console.log("POST EVENT", message);
    iframeEl.value.contentWindow.postMessage(message, "*");
  }
}

function onScroll(evt: any) {
  scrollTop.value = evt.target.scrollTop;
}

function toggleScrollEvt(remove = false) {
  const parentScroll = document.getElementsByClassName("protected__holder")[0];
  if (parentScroll) {
    parentScroll[remove ? "removeEventListener" : "addEventListener"](
      "scroll",
      onScroll
    );
    scrollTop.value = parentScroll.scrollTop;
  }
}

const checkAgentLoaded = () => {
  setTimeout(() => {
    if (!agentLoaded.value) {
      loadingError.value = true;
    }
  }, 60000); // 60000 milliseconds = 1 minute
};

function reloadSlide() {
  webglComposable.hide();
  webglComposable.show();
  webglComposable.init();
  loadingError.value = false;
}

onMounted(() => {
  webglComposable.init();

  eventComposable.on(events.WEBGL_HIDE, hide);
  eventComposable.on(events.WEBGL_SHOW, show);
  eventComposable.on(events.WEBGL_RESIZE, setStyle);
  checkAgentLoaded();
  window.addEventListener("resize", setStyle);

  if (!webglComposable.audioMode.value) {
    eventComposable.on(events.WEBGL_POST, postMessage);
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.action) {
          console.log("NEW EVENT", event.data);
          webglComposable.onReceiveEvt(event.data);
        }
      },
      false
    );
  }
});

return (_ctx: any,_cache: any) => {
  const _component_hia_progress_circular = _resolveComponent("hia-progress-circular")!
  const _component_hia_btn = _resolveComponent("hia-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["webgl", { webgl__inactive: !active.value }]),
    style: _normalizeStyle(computedStyle.value)
  }, [
    _createElementVNode("div", {
      class: "webgl__iframe",
      style: _normalizeStyle(iframeStyle.value)
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_unref(webglComposable).audioMode.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: _ctx.$t('webgl.browser_not_supported')
              }, null, 8, _hoisted_3),
              _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"webgl__browser-icons\"><a href=\"https://www.google.com/chrome/\" target=\"_blank\"><img src=\"" + _imports_0 + "\" alt=\"Google Chrome\"><p>Google Chrome</p></a><a href=\"https://www.mozilla.org/firefox/new/\" target=\"_blank\"><img src=\"" + _imports_1 + "\" alt=\"Mozilla Firefox\"><p>Mozilla Firefox</p></a></div>", 1))
            ]))
          : (!initialized.value || (!agentLoaded.value && !loadingError.value))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_hia_progress_circular, {
                  xl: "",
                  color: "primary"
                }),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("project.loading_preview")), 1)
              ]))
            : (!initialized.value || (!agentLoaded.value && loadingError.value))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("project.loading_preview_error")), 1),
                  _createVNode(_component_hia_btn, {
                    primary: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (reloadSlide()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("project.loading_preview_error_button")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
        (!_unref(webglComposable).audioMode.value)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 3,
              src: iframeUrl.value,
              width: "100%",
              height: "100%",
              allow: "microphone;autoplay",
              ref_key: "iframeEl",
              ref: iframeEl
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ], 6))
}
}

})