<template>
  <div class="app">
    <WebglVue />
    <hia-notification />
    <div v-show="!closeNotifications">
      <SideNotifications />
    </div>

    <hia-context-menu />
    <hia-confirmation-modal />
    <router-view v-slot="{ Component }">
      <transition name="route-transition">
        <component :is="Component" class="transition-all duration-300" />
      </transition>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import {
  useAuth,
  useColor,
  usePublishListener,
  useTranslation,
} from "@/composables";
import { useRouter } from "vue-router";
import { onBeforeMount } from "vue";
import WebglVue from "./components/projects/webgl/Webgl.vue";
import { useStore } from "vuex";
import SideNotifications from "@/components/dashboard/SideNotifications.vue";
import { closeNotifications } from "@/composables/notificationsLocal";

const authComposable = useAuth();
const translationComposable = useTranslation();
const router = useRouter();
const store = useStore();
authComposable.init();

let loaded = false;

router.beforeEach(async (to, from, next) => {
  if (!loaded) {
    try {
      translationComposable.setLang();
      await authComposable.getUserInfo();
      loaded = true;
    } catch (error) {
      loaded = true;
    }
  }

  const isLogged = !!store.state.Auth.token;
  const routerName = to.name as string;
  const isAuthRoute = routerName.includes("auth");

  if (isLogged) {
    let isRouteAllowed = true;

    const routeRoles = to.meta.roles as Array<string>;

    const isAuthVerifyRoute = [
      "auth.reset_password",
      "auth.verify-user",
    ].includes(routerName);

    if (routeRoles && routeRoles.length) {
      isRouteAllowed = routeRoles.includes(store.state.Auth.info.role);
    }

    if (!isAuthVerifyRoute && (isAuthRoute || !isRouteAllowed)) {
      next({ name: "protected" });
    } else {
      next();
    }
  } else {
    if (isAuthRoute) {
      next();
    } else {
      next({ name: "auth.login" });
    }
  }
});

onBeforeMount(() => {
  useColor().init();
  usePublishListener().init();
});
</script>

<style lang="scss">
.app {
  @apply h-screen w-screen overflow-hidden relative bg-b-app;
}
</style>
