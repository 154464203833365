const events: any = {};

export const useEvent = () => {
  function on(eventName: string, fn: any) {
    events[eventName] = events[eventName] || [];
    events[eventName].push(fn);
  }
  function off(eventName: string) {
    delete events[eventName];
  }
  function emit(eventName: string, data?: any) {
    if (events[eventName]) {
      events[eventName].forEach(function (fn: any) {
        fn(data);
      });
    }
  }
  return {
    on,
    off,
    emit,
  };
};
