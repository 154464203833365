import { defineRule, validate } from "vee-validate";
import { i18n } from "@/composables/translation";

defineRule("required", (value: any) => {
  if (!value || !value.length) {
    return i18n.global.t("input.errors.required");
  }
  return true;
});

defineRule("email", (value: string) => {
  if (!value || !value.length) {
    return true;
  }
  if (
    !String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return i18n.global.t("input.errors.email");
  }
  return true;
});

defineRule("confirmed", (value: string, [target]: Array<any>) => {
  if (value === target) {
    return true;
  }
  return i18n.global.t("input.errors.password_match");
});

defineRule("minLength", (value: string, [min]: Array<any>) => {
  if (!value || !value.length) {
    return true;
  }
  const numericValue = Number(value.length);
  if (numericValue < min) {
    return i18n.global.t("input.errors.min_length", { min });
  }
  return true;
});

defineRule("maxLength", (value: string, [max]: Array<any>) => {
  if (!value || !value.length) {
    return true;
  }
  const numericValue = Number(value.length);
  const maxLength = max ? max : 52;
  if (numericValue > maxLength) {
    return i18n.global.t("input.errors.max_length", { maxLength });
  }
  return true;
});

defineRule("minValue", (value: number, [min]: Array<any>) => {
  if (value < Number(min)) {
    return i18n.global.t("input.errors.min_value", { min });
  }
  return true;
});

defineRule("maxValue", (value: number, [max]: Array<any>) => {
  if (value > Number(max)) {
    return i18n.global.t("input.errors.max_value", { max });
  }
  return true;
});

defineRule("excluded", (value: string, [items]: Array<string>) => {
  const itemsArr = items.split(",");
  if (itemsArr.includes(value)) {
    return i18n.global.t("input.errors.excluded");
  }
  return true;
});

defineRule("number", (value: number) => {
  if (isNaN(value)) {
    return i18n.global.t("input.errors.number");
  }
  return true;
});

defineRule("length", (value: any, [length]: Array<number>) => {
  if (value.length < length) {
    return i18n.global.t("input.errors.length", { length });
  }
  return true;
});

defineRule("url", (value: string) => {
  const regex =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regex.test(value);
});

defineRule("specialCharacter", (value: string) => {
  const regex = /\W|_/g;
  if (regex.test(value) === false) {
    return i18n.global.t("input.errors.special_character");
  }
  return true;
});

defineRule("digits", (value: string) => {
  const regex = /\d/g;
  if (regex.test(value) === false) {
    return i18n.global.t("input.errors.digits");
  }
  return true;
});

defineRule("emailOrUrl", async (value: string) => {
  const isEmail = await validate(value, "email");
  const isURl = await validate(value, "url");
  return isEmail.valid || isURl.valid;
});
