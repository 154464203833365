import "./static/css/main.scss";
import "@vuepic/vue-datepicker/dist/main.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "vue-advanced-cropper/dist/style.css";

import _ from "lodash";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createApp, defineAsyncComponent } from "vue";
// Plugins
import "./plugins/veelidateRules";
import globalComponents from "./plugins/globalComponents";
import dayjs from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import dayjsDuration from "dayjs/plugin/duration";
import Datepicker from "@vuepic/vue-datepicker";
import VueVirtualScroller from "vue-virtual-scroller";
import localizedFormat from "dayjs/plugin/localizedFormat";
// Constants
import icons from "./assets/icons/icons";
import events from "./static/ts/events";
import { getEndpoints } from "./static/ts/endpoints";
import constants from "./static/ts/constants";
import { initApiConfig, apiConfig } from "./static/ts/apiConfig";

// Composables
import { useTranslation } from "./composables";

let endpoints = getEndpoints();

declare global {
  type DayjsEnum = typeof dayjs;
  type IconsEnum = typeof icons;
  type EventsEnum = typeof events;
  type EndpointsEnum = typeof endpoints;
  type ConstantsEnum = typeof constants;
  type ApiConfigEnum = typeof apiConfig;
}

async function init() {
  await initApiConfig();
  endpoints = getEndpoints();
  dayjs.extend(dayjsUtc);
  dayjs.extend(dayjsDuration);
  dayjs.extend(localizedFormat);

  const app = createApp(App);
  const translationsComposable = useTranslation();

  _.forEach(globalComponents, (value) => {
    app.component(
      `hia-${value.name}`,
      defineAsyncComponent(() => import(`@/components/${value.component}`))
    );
  });

  app.component("Date-picker", Datepicker);

  const i18n = await translationsComposable.init();

  app.provide("dayjs", dayjs);
  app.provide("icons", icons);
  app.provide("events", events);
  app.provide("endpoints", endpoints);
  app.provide("constants", constants);
  app.provide("apiConfig", apiConfig);
  app.provide("translation", translationsComposable.translate());

  app.use(i18n).use(store).use(router).use(VueVirtualScroller).mount("#app");

  if (apiConfig.INSTANCE !== "PROD") {
    document.title = `${apiConfig.INSTANCE} - HIA Aivio Studio`;
  }

  store.commit("Settings/setSmallScreen", window.innerWidth <= 1536);

  addEventListener("resize", () => {
    store.commit("Settings/setSmallScreen", window.innerWidth <= 1536);
  });
}

init();
