import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useSlideUtils } from "./slide";
import { Module, ModuleMediaTypes } from "@/models/module";
import {
  ModuleNavigation,
  ModuleNavigationPutPost,
  ModuleNavigationButton,
} from "@/models/moduleNavigation";
import { useAsset } from "./asset";
import { ProjectAvatarVoiceTimeIndex } from "@/models/project";

export const useModuleNavigation = () => {
  const dayjs = inject("dayjs") as DayjsEnum;

  const endpoints = inject("endpoints") as EndpointsEnum;
  const store = useStore();
  const assetComposable = useAsset();
  const slideUtilsComposable = useSlideUtils();

  const { email, id: userId } = store.state.Auth.originalInfo;

  async function get(moduleData: Module) {
    let moduleNavigation: ModuleNavigation;

    if (moduleData.navigation) {
      moduleNavigation = await axios.get(endpoints.NAVIGATION.GET, {
        params: {
          navigationId: moduleData.navigation.navigationId_chr,
        },
      });
    } else {
      moduleNavigation = await createUpdate(createObject(moduleData));
    }

    if (
      !!moduleNavigation.utterance &&
      !!moduleNavigation.utterance.mediaTrigger
    ) {
      const assets: Array<{ url: string; type: ModuleMediaTypes }> = _.map(
        moduleNavigation.utterance.mediaTrigger.media,
        (media) => {
          return {
            url: media.mediaURL,
            type: media.triggerType,
          };
        }
      );

      await assetComposable.getModuleAssets(assets);

      moduleNavigation.utterance._layers =
        slideUtilsComposable.createModuleLayersObject(
          moduleNavigation.utterance.line_chr,
          moduleNavigation.utterance
        );
      if (!moduleNavigation.utterance.line_chr) {
        moduleNavigation.utterance.line_chr = "";
      }
    } else {
      moduleNavigation.utterance = {
        ...slideUtilsComposable.createBaseResponseObject(),
        line_chr: "",
        speechTime_lng: 0,
      };
    }

    return moduleNavigation;
  }

  function createObject(
    moduleData: Module,
    navigationModule?: ModuleNavigation,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    const navigationObject: ModuleNavigationPutPost = {
      navigationId_chr: null,
      moduleId_chr: moduleData.moduleId,
      speechTime_lng: 0,
      fullScrMedia: false,
      navigationButtons: [],
      lastUpdatedByUserId_chr: email,
      lastUpdatedByUserId: userId,
      lastUpdatedDateTime_dtm: dayjs.utc().format(),
      utterance: {
        ...slideUtilsComposable.createBaseObject(),
        line_chr: null,
        speechTime_lng: 0,
      },
      showCme: true,
    };

    if (navigationModule) {
      const {
        navigationId_chr,
        fullScrMedia,
        navigationButtons,
        utterance,
        showCme,
      } = navigationModule;

      navigationObject.showCme = showCme;
      navigationObject.fullScrMedia = fullScrMedia;
      navigationObject.navigationId_chr = navigationId_chr;
      navigationObject.navigationButtons = navigationButtons;
      navigationObject.utterance = {
        ...slideUtilsComposable.createBaseObject(
          utterance.line_chr,
          utterance._layers,
          timeIndexes ? _.map(timeIndexes, (v) => v.value) : []
        ),
        line_chr: utterance.line_chr,
        speechTime_lng: utterance.speechTime_lng,
      };
    }

    return navigationObject;
  }

  function createButtonObject() {
    const navigationButton: ModuleNavigationButton = {
      buttonLabel: "",
      navigateTomodule: {
        moduleId: "",
        moduleName: "",
      },
      navigationType: null,
      url: null,
    };

    return navigationButton;
  }

  function createUpdate(navigationInfo: ModuleNavigationPutPost) {
    navigationInfo.lastUpdatedByUserId = email;
    return axios
      .post(endpoints.NAVIGATION.CREATE_UPDATE, navigationInfo)
      .then((response: any) => {
        return response as ModuleNavigation;
      });
  }

  return {
    createUpdate,
    createObject,
    createButtonObject,
    get,
  };
};
