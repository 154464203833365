import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import constants from "@/static/ts/constants";

const state = {
  darkMode: false,
  lang: constants.LANGUAGES.EN,
  isSmallScreen: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
