const _import = (path: string) => import(`@/views/protected/share/${path}.vue`);

export default [
  {
    path: "share",
    name: "protected.share",
    component: () => _import("Share"),
    meta: {
      name: "share.base",
      hidePadding: false,
      hideContentHeader: true,
    },
    redirect: {
      name: "protected.share.list",
    },
    children: [
      {
        path: "/list",
        name: "protected.share.list",
        component: () => _import("List"),
      },
      {
        path: "/create/:aivioId?",
        name: "protected.share.create",
        component: () => _import("Create"),
        meta: {
          name: "share.create",
        },
      },
    ],
  },
];
