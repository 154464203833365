import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

import {
  useAuth,
  useColor,
  usePublishListener,
  useTranslation,
} from "@/composables";
import { useRouter } from "vue-router";
import { onBeforeMount } from "vue";
import WebglVue from "./components/projects/webgl/Webgl.vue";
import { useStore } from "vuex";
import SideNotifications from "@/components/dashboard/SideNotifications.vue";
import { closeNotifications } from "@/composables/notificationsLocal";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const authComposable = useAuth();
const translationComposable = useTranslation();
const router = useRouter();
const store = useStore();
authComposable.init();

let loaded = false;

router.beforeEach(async (to, from, next) => {
  if (!loaded) {
    try {
      translationComposable.setLang();
      await authComposable.getUserInfo();
      loaded = true;
    } catch (error) {
      loaded = true;
    }
  }

  const isLogged = !!store.state.Auth.token;
  const routerName = to.name as string;
  const isAuthRoute = routerName.includes("auth");

  if (isLogged) {
    let isRouteAllowed = true;

    const routeRoles = to.meta.roles as Array<string>;

    const isAuthVerifyRoute = [
      "auth.reset_password",
      "auth.verify-user",
    ].includes(routerName);

    if (routeRoles && routeRoles.length) {
      isRouteAllowed = routeRoles.includes(store.state.Auth.info.role);
    }

    if (!isAuthVerifyRoute && (isAuthRoute || !isRouteAllowed)) {
      next({ name: "protected" });
    } else {
      next();
    }
  } else {
    if (isAuthRoute) {
      next();
    } else {
      next({ name: "auth.login" });
    }
  }
});

onBeforeMount(() => {
  useColor().init();
  usePublishListener().init();
});

return (_ctx: any,_cache: any) => {
  const _component_hia_notification = _resolveComponent("hia-notification")!
  const _component_hia_context_menu = _resolveComponent("hia-context-menu")!
  const _component_hia_confirmation_modal = _resolveComponent("hia-confirmation-modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(WebglVue),
    _createVNode(_component_hia_notification),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(SideNotifications)
    ], 512), [
      [_vShow, !_unref(closeNotifications)]
    ]),
    _createVNode(_component_hia_context_menu),
    _createVNode(_component_hia_confirmation_modal),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, { name: "route-transition" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "transition-all duration-300" }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}
}

})