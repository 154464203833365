import _ from "lodash";
import { useStore } from "vuex";
import { useStorage } from "./storage";
import { ComponentState } from "@/models/utils";

export const useComponentState = () => {
  const store = useStore();
  const storageComposable = useStorage();

  const { id: userId } = store.state.Auth.originalInfo;

  function set(state: ComponentState) {
    const storageStates = storageComposable.get("component-states") || {};
    let states: Array<ComponentState> = storageStates[userId]
      ? storageStates[userId]
      : [];

    states = _.filter(
      states,
      (s) =>
        s.componentName != state.componentName &&
        s.componentId != state.componentId
    );

    states.push(state);

    storageStates[userId] = states;

    storageComposable.set("component-states", storageStates);
  }

  function get(componentId: string, componentName: string) {
    const storageStates = storageComposable.get("component-states") || {};
    const states: Array<ComponentState> = storageStates[userId]
      ? storageStates[userId]
      : [];

    return _.find(
      states,
      (state) =>
        state.componentName === componentName &&
        state.componentId === componentId
    );
  }

  function remove(componentId: string, componentName: string) {
    const storageStates = storageComposable.get("component-states") || {};
    let states: Array<ComponentState> = storageStates[userId]
      ? storageStates[userId]
      : [];

    states = _.filter(
      states,
      (s) => s.componentName != componentName && s.componentId != componentId
    );

    storageStates[userId] = states;

    storageComposable.set("component-states", storageStates);
  }

  return {
    set,
    get,
    remove,
  };
};
