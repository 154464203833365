import lang from "@/lang";
import { useStore } from "vuex";
import { useStorage } from "./storage";
import { createI18n } from "vue-i18n";
import { useUtils } from "./utils";
import constants from "@/static/ts/constants";

export let i18n: any = {};

export const useTranslation = () => {
  const utilsComposable = useUtils();

  async function init() {
    const messages = await lang.getTranlations();

    i18n = createI18n({
      locale: "en",
      fallbackLocale: "en",
      messages: messages,
    });

    return i18n;
  }

  function getUserLanguage() {
    const savedLang = useStorage().get("language");
    if (savedLang) return savedLang;

    let browserLanguage = utilsComposable.getLanguageString(
      constants.LANGUAGES.EN
    );

    switch (navigator.language) {
      case "es":
        browserLanguage = utilsComposable.getLanguageString(
          constants.LANGUAGES.ES
        );
        break;
    }

    return browserLanguage;
  }

  function setLang(lang?: string) {
    if (!lang) lang = getUserLanguage();
    if (i18n) i18n.global.locale = lang;
    useStorage().set("language", lang);
    useStore().commit("Settings/setLang", lang);
  }

  function translate() {
    return i18n.global.t;
  }

  return {
    init,
    i18n,
    setLang,
    translate,
  };
};
