import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Auth from "./auth/Auth";
import Protected from "./protected/Protected";

const routes: Array<RouteRecordRaw> = [...Auth, ...Protected];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
