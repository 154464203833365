import { createStore } from "vuex";
import Auth from "./Auth";
import Asset from "./Asset";
import Settings from "./Settings";
import Module from "./Module";
import Content from "./Content";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Asset,
    Settings,
    Module,
    Content,
  },
});
