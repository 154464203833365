import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useNotification } from "./notification";
import { useModule } from "./module";
import { useComponentState } from "./componentState";
import { useAiTopic } from "./aiTopic";
import { Module, ModuleBaseResponse, ModuleMediaTypes } from "@/models/module";
import { Language } from "@/models/utils";
import { useRouter, useRoute } from "vue-router";
import {
  Project,
  ProjectAgentLinesBase,
  ProjectAgentLines,
  ProjectWithExtraInfo,
  ProjectAvatar,
  ProjectContentItem,
  ProjectListResponse,
  ProjectMostRecent,
} from "@/models/project";
import { GetAvatar, SetVisibility } from "@/models/avatar";
import { useAsset } from "./asset";
import { useUtils } from "./utils";

export const useProject = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const constants = inject("constants") as ConstantsEnum;

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const utilsComposable = useUtils();
  const assetComposable = useAsset();
  const moduleComposable = useModule();
  const routerComposable = useRouter();
  const aiTopicComposable = useAiTopic();
  const componentState = useComponentState();
  const notificationComposable = useNotification();

  const { clientId, id, email, clientName, lang } =
    store.state.Auth.originalInfo;

  function getProjects(
    _clientId?: number,
    pageLimit = 15,
    currentPage = 0,
    search = "",
    status = "all"
  ) {
    if (!_clientId) _clientId = clientId;
    return axios
      .get(endpoints.PROJECTS.LIST, {
        params: {
          clientId: _clientId,
          pageIndex: currentPage,
          search: search.toLowerCase(),
          pageLimit,
          status,
        },
      })
      .then((response: any) => response as ProjectListResponse);
  }

  function updateTimeline(
    projectId: string,
    activeList: Array<Module>,
    showNotification = true
  ) {
    return axios
      .post(endpoints.PROJECTS.UPDATE_MODULE_LIST, {
        projectId,
        activeList,
        lastUpdatedByUserId: email,
      })
      .then((project: any) => {
        if (showNotification) {
          notificationComposable.success("project.update_timeline_success");
        }
        return project as Project;
      })
      .catch((err) => {
        if (showNotification) {
          notificationComposable.error("project.update_timeline_success");
        }
        throw err;
      });
  }

  async function getProject(projectId: string, isAdmin = false) {
    const projectInfo: ProjectWithExtraInfo = await axios.get(
      endpoints.PROJECTS.GET,
      { params: { projectId } }
    );

    if (!isAdmin && projectInfo.project.clientID_lng !== clientId) {
      routerComposable.push({
        path: "/projects",
      });
      throw notificationComposable.error("project.permission_declined");
    }

    const assetsToLoad: Array<{ url: string; type: ModuleMediaTypes }> = [];

    if (
      projectInfo.project.introVideoUrl &&
      utilsComposable.isUrl(projectInfo.project.introVideoUrl)
    ) {
      assetsToLoad.push({
        url: projectInfo.project.introVideoUrl,
        type: "video",
      });
    }

    if (projectInfo.project.defaultImageUrl) {
      assetsToLoad.push({
        url: projectInfo.project.defaultImageUrl,
        type: "image",
      });
    }

    await assetComposable.getModuleAssets(assetsToLoad);

    return projectInfo;
  }

  async function duplicateProject(projectId: string) {
    try {
      const { project: originalProject } = await getProject(projectId);
      const newProject = await createUpdateProject(
        `${originalProject.projectName} (copy)`,
        originalProject.agentLines,
        originalProject.avatarId,
        originalProject.lang,
        originalProject.defaultImageUrl,
        originalProject.defaultClickableAction,
        null,
        originalProject.introVideoUrl,
        false
      );

      if (originalProject.projectId && newProject.projectId) {
        const activeModulesList = await moduleComposable.getTimelineModules(
          originalProject.projectId
        );
        await updateTimeline(
          newProject.projectId,
          _.map(activeModulesList, "module"),
          false
        );
      }

      notificationComposable.success("project.duplicate_project_success");
    } catch (error) {
      notificationComposable.error("project.duplicate_project_error");
    }
  }

  async function deleteProject(projectId: string) {
    return axios
      .delete(endpoints.PROJECTS.DELETE, {
        params: {
          projectId,
          force: true,
        },
      })
      .then(() => {
        notificationComposable.success("project.delete_project_success");
      })
      .catch((error) => {
        notificationComposable.error("project.delete_project_fail");
        throw error;
      });
  }

  function getAvatars(onlyVisible = false, lang?: string) {
    return axios
      .get(endpoints.AVATARS.LIST_ALL, {
        params: {
          clientId,
        },
      })
      .then((response: any) => {
        const avatarResponse = response.avatarItems as Array<ProjectAvatar>;
        const avatarList = lang
          ? _.filter(avatarResponse, (avatar: ProjectAvatar) =>
              avatar.voice.includes(lang)
            )
          : avatarResponse;

        _.forEach(avatarList, (_avatar) => {
          if (!_avatar.rate) _avatar.rate = "default";
          if (!_avatar.pitch) _avatar.pitch = "default";
        });
        if (onlyVisible) {
          return _.filter(avatarList, (avatar) => avatar.isVisible);
        }
        return avatarList;
      });
  }

  function getAvatar(id?: string) {
    return axios
      .get(endpoints.AVATARS.LIST, {
        params: {
          id,
        },
      })
      .then((response: any) => {
        return response as GetAvatar;
      });
  }

  function SetAvatarVisibility(avatarId?: string, isVisible?: boolean) {
    return axios
      .put(endpoints.AVATARS.SET_VISIBLE, {
        params: {
          avatarId,
          isVisible,
        },
      })
      .then((response: any) => {
        return response as SetVisibility;
      });
  }

  function getOtherLines() {
    return axios.get(endpoints.AVATARS.OTHER_LINES).then((response: any) => {
      return response.data as Array<ProjectAgentLines>;
    });
  }

  function createUpdateProject(
    projectName: string | null,
    agentLines: Array<ProjectAgentLinesBase>,
    avatarId: string | null,
    lang: Language | null = Language.en,
    defaultImageUrl?: string | null,
    defaultClickableAction?: string | null,
    projectId?: string | null,
    introVideoUrl?: string | null,
    showNotification = true
  ) {
    return axios
      .post(endpoints.PROJECTS.CREATE_UPDATE, {
        projectName,
        agentLines,
        avatarId,
        lang,
        defaultImageUrl,
        defaultClickableAction,
        calling_UserID_chr: id,
        clientID_lng: clientId,
        lastUpdatedByUserId: email,
        introVideoUrl,
        projectId,
      })
      .then((response: any) => {
        if (showNotification) {
          notificationComposable.success("project.create_project_success");
        }
        return response as Project;
      })
      .catch((error) => {
        if (showNotification) {
          notificationComposable.error("project.create_project_error");
        }
        throw error;
      });
  }

  function publishProject(projectId: string) {
    return axios
      .post(endpoints.PROJECTS.PUBLISH, null, {
        params: { projectId },
      })
      .then((response: any) => {
        notificationComposable.success("notification.publish_project_success");
        return response;
      })
      .catch((_error) => {
        const error = _error.response.data.toString().toLowerCase();
        let errorMsg = "project.publish_project_error";
        if (error.includes("topic")) {
          errorMsg = "project.publish_project_error_topic";
        } else if (error.includes("navigation")) {
          errorMsg = "project.publish_project_error_navigation";
        } else if (error.includes("speech")) {
          errorMsg = "project.publish_project_error_speech";
        }
        notificationComposable.error(errorMsg);
        throw error;
      });
  }

  function generateLines() {
    return [
      {
        lineText: [],
        lineType: "noAnswer",
      },
      {
        lineText: [],
        lineType: "fallback",
      },
      {
        lineText: [],
        lineType: "interrupt",
      },
      {
        lineText: [],
        lineType: "resume",
      },
    ];
  }

  function revertProjectToPublished(projectId: string) {
    return axios
      .post(endpoints.PROJECTS.REVERT_TO_PUBLISHED, null, {
        params: { projectId },
      })
      .then((response: any) => {
        notificationComposable.success("project.revert_project_success");
        return response as Project;
      })
      .catch((error) => {
        notificationComposable.error("project.revert_project_error");
        throw error;
      });
  }

  function saveCopy(
    projectId: string,
    newProjectName: string,
    clientNameToCopyTo?: string,
    clientIdToCopyTo?: number
  ) {
    if (!clientIdToCopyTo) clientIdToCopyTo = clientId;
    if (!clientNameToCopyTo) clientNameToCopyTo = clientName;
    return axios
      .get(endpoints.PROJECTS.COPY, {
        params: {
          projectId,
          clientIdToCopyTo,
          newProjectName,
          clientName: clientNameToCopyTo,
        },
      })
      .then((response: any) => {
        notificationComposable.success("project.copy_project_success");
        return response as Project;
      })
      .catch((error) => {
        notificationComposable.error("project.copy_project_error");
        throw error;
      });
  }

  function setIntroVideo(projectId: string, videoUrl: string | null) {
    return axios.post(endpoints.PROJECTS.SET_INTRO_VIDEO, null, {
      params: {
        projectId,
        videoUrl,
      },
    });
  }

  async function getContents(
    projectId: string | null,
    clientId: number | null = null,
    lang: Language,
    moduleType?: string
  ) {
    let aiTopics;
    let modules: Array<ModuleBaseResponse> = [];
    const contents: Array<ProjectContentItem> = [];
    if (moduleType === "AiTopic" || !moduleType) {
      aiTopics = await aiTopicComposable.getAll(clientId, lang);
    }
    if (moduleType !== "AiTopic") {
      modules = await moduleComposable.getModulesList(
        projectId,
        clientId,
        moduleType
      );
    }

    _.forEach(modules, (_data) => {
      const _module = moduleComposable.makeContentObject(_data);
      if (!_data.module.moduleId) return;
      contents.push(_module);
    });

    _.forEach(aiTopics, (_data) => {
      const _topic = aiTopicComposable.makeContentObject(_data);
      if (!_data.topic.topicId || !_topic.id) return;
      contents.push(_topic);
    });

    if (moduleType) {
      store.commit("Module/setModule", {
        type: moduleType.toLowerCase(),
        modules: moduleType === "AiTopic" ? aiTopics : modules,
      });
      store.commit("Content/setContent", {
        type: moduleType.toLowerCase(),
        contents: contents,
      });
    }

    return { modules, aiTopics, contents };
  }

  function goToEditContent(content: ProjectContentItem) {
    if (content.type === "AiTopic") {
      aiTopicComposable.goToEdit(content.id);
    } else if (content.type === "Project") {
      router.push({
        name: "protected.projects.project",
        params: {
          projectId: content.id,
        },
        query: {
          from: "dashboard",
        },
      });
    } else {
      moduleComposable.goToEditModule(content.id, content.type, undefined);
    }
  }

  function currentProjectIdFromStore() {
    const project = componentState.get("project-page", "ProjectPage");
    if (project) {
      return project.info.recentProjectId === route.params.id;
    }
    return false;
  }

  function getDefaultAvatar(avatarList: Array<ProjectAvatar>) {
    const visibleAvatars = _.filter(avatarList, (_avatar) => _avatar.isVisible);
    const carlosBusinessAvatar = _.find(
      visibleAvatars,
      (_avatar) => _avatar.avatarName === "CarlosBusiness"
    );
    const avatar = carlosBusinessAvatar || visibleAvatars[0];
    return avatar;
  }

  function clearPronunciations(projectId: string) {
    return axios
      .post(endpoints.PROJECTS.CLEAR_PRONUNCIATIONS, null, {
        params: {
          projectId,
          lastUpdatedByUserId: id,
        },
      })
      .then(() => {
        notificationComposable.success(
          "project.remove_pronunciation_successfully"
        );
      })
      .catch(() => {
        notificationComposable.error("project.remove_pronunciation_failed");
      });
  }

  async function renameProject(projectId: string, projectName: string) {
    const project = await getProject(projectId);
    project.project.projectName = projectName;
    return axios
      .post(endpoints.PROJECTS.CREATE_UPDATE, {
        ...project.project,
        calling_UserID_chr: id,
        lastUpdatedByUserId: id,
      })
      .catch(() => {
        notificationComposable.error("project.fail_to_update_project");
      });
  }

  function clearCachedContents() {
    store.commit("Module/clearState");
    store.commit("Content/clearState");
    componentState.remove("project-page", "ProjectPage");
    componentState.remove("last-module", "LastModule");
  }

  function getRecentProjects() {
    return axios
      .get(endpoints.PROJECTS.GET_MOST_RECENT, {
        params: {
          clientId,
        },
      })
      .then((response: any) => {
        const projects = response as Array<ProjectMostRecent>;
        const contents = [] as Array<ProjectContentItem>;
        _.forEach(projects, (p) => {
          contents.push(generateContentItemProject(p));
        });
        return contents;
      });
  }

  function generateContentItemProject(project: ProjectMostRecent) {
    const contentItem = {
      displayStatus: "Published",
      id: project.projectId,
      name: project.projectName,
      type: "Project",
      lang: lang,
      status: constants.STATE_STATUS.PUBLISHED,
      tags: [],
    } as ProjectContentItem;
    return contentItem;
  }

  function getProjectModulesUnpublished(projectId: string) {
    return axios
      .get(endpoints.PROJECTS.GET_PROJECT_MODULES_UNPUBLISHED_CHANGES, {
        params: {
          projectId,
        },
      })
      .then((response: any) => {
        const _modules = response as Array<Module>;
        return _.map(_modules, (m: Module) => {
          return {
            moduleName: m.moduleName,
            moduleId: m.moduleId,
            moduleType: m.moduleType_chr,
          };
        });
      });
  }

  return {
    getProject,
    getProjects,
    updateTimeline,
    deleteProject,
    getAvatars,
    getOtherLines,
    createUpdateProject,
    duplicateProject,
    generateLines,
    publishProject,
    revertProjectToPublished,
    saveCopy,
    setIntroVideo,
    getContents,
    goToEditContent,
    clearPronunciations,
    renameProject,
    clearCachedContents,
    getRecentProjects,
    getAvatar,
    SetAvatarVisibility,
    getDefaultAvatar,
    currentProjectIdFromStore,
    getProjectModulesUnpublished,
  };
};
