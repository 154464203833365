import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  info: {},
  originalInfo: {},
  token: "",
  impersonate: {
    type: null,
    id: null,
    originalId: null,
    token: null,
    clientName: null,
  },
  darkMode: false,
  clientAccess: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
