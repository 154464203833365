import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useNotification } from "./notification";
import {
  Module,
  ModuleBaseResponse,
  ModuleData,
  ModuleMostRecent,
  ModuleTypes,
} from "@/models/module";
import { AiTopic } from "@/models/aiTopic";
import { Tag } from "@/models/tag";
import { Language } from "@/models/utils";
import { Project, ProjectContentItem } from "@/models/project";
import _ from "lodash";
import { useUtils } from "./utils";

export const useModule = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const constants = inject("constants") as ConstantsEnum;

  const store = useStore();
  const router = useRouter();
  const notificationComposable = useNotification();
  const utilsComposable = useUtils();
  const route = useRoute();

  const { clientId, id, email } = store.state.Auth.originalInfo;

  function createModule(
    type: ModuleTypes | "Project",
    name: string,
    tags: Array<Tag> = [],
    lang: Language = Language.en
  ) {
    const payload = {
      moduleName: name,
      tags: tags,
      moduleType_chr: type,
      clientId: clientId,
      calling_UserID_chr: id,
      lastUpdatedByUserId: email,
      lang,
    };

    return axios
      .post(endpoints.MODULES.CREATE, payload)
      .then((response: any) => {
        notificationComposable.success("module.create_module_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("module.create_module_error");
        throw error;
      });
  }

  function makeContentObject(
    moduleResponse: ModuleBaseResponse
  ): ProjectContentItem {
    const _module = moduleResponse.module;
    return {
      id: _module.moduleId,
      name: _module.moduleName,
      type: _module.moduleType_chr,
      tags: _module.tags,
      status: _module.status,
      lang: _module.lang,
      createdAt: _module.createdDateTime,
      displayStatus: utilsComposable.getStatusName(_module.status),
      lastUpdateAt: _module.lastUpdatedDateTime,
      extraInfo: {
        length: moduleResponse.length,
        questionsCount: moduleResponse.questionsCount,
        slidesCount: moduleResponse.slidesCount,
        mediasCount: moduleResponse.mediasCount,
        projectsCount: moduleResponse.projectsCount,
      },
    };
  }

  function getModulesList(
    projectId: string | null,
    clientId: number | null,
    moduleType?: string | null
  ) {
    return axios
      .get(endpoints.MODULES.GET_ALL_MODULELIST, {
        params: {
          clientId,
          projectId,
          moduleType,
        },
      })
      .then((response: any) => response as Array<ModuleBaseResponse>)
      .catch((err) => {
        throw err;
      });
  }

  function getTimelineModules(projectId: string | null) {
    return axios
      .get(endpoints.MODULES.TIMELINE_MODULES, {
        params: {
          projectId,
        },
      })
      .then((response: any) => response as Array<ModuleBaseResponse>)
      .catch((err) => {
        throw err;
      });
  }

  function get(moduleId: string) {
    return axios
      .get(endpoints.MODULES.GET, {
        params: {
          moduleId,
        },
      })
      .then((response: any) => {
        const module: Module = response;
        if (module.clientID_lng !== clientId) {
          router.push({
            path: "/projects",
          });

          throw notificationComposable.error("project.permission_declined");
        }
        return module;
      });
  }

  function getModuleData(moduleId: string) {
    return axios
      .get(endpoints.MODULES.GET_MODULE_DATA, {
        params: {
          moduleId,
        },
      })
      .then((response: any) => {
        return response as ModuleData;
      })
      .catch((err) => {
        throw err;
      });
  }

  function deleteModule(moduleId: string) {
    return axios
      .delete(endpoints.MODULES.DELETE, {
        params: { moduleId },
      })
      .then(() => {
        notificationComposable.success("module.delete_module_success");
      })
      .catch((error) => {
        if (error.response.status === 409) {
          notificationComposable.error("module.conflict_error");
          throw error;
        } else {
          notificationComposable.error("module.delete_module_error");
          throw error;
        }
      });
  }

  function updateName(moduleId: string, moduleName: string) {
    return axios
      .put(endpoints.MODULES.UPDATE_NAME, {
        moduleId,
        moduleName,
        lastUpdatedByUserId: id,
      })
      .then((response) => {
        notificationComposable.success("module.rename_module_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("module.rename_module_error");
        throw error;
      });
  }

  function publishModule(moduleId: string) {
    return axios
      .post(endpoints.MODULES.PUBLISH, null, {
        params: { moduleId },
      })
      .then((response: any) => {
        notificationComposable.success("notification.publish_module_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error(
          error.response.data.includes("unpublished topic")
            ? "module.publish_module_error_topic"
            : "module.publish_module_error"
        );
        throw error;
      });
  }

  function revertModuleToPublished(moduleId: string) {
    return axios
      .post(endpoints.MODULES.REVERT_TO_PUBLISHED, null, {
        params: { moduleId },
      })
      .then((response: any) => {
        notificationComposable.success("module.revert_module_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("module.revert_module_error");
        throw error;
      });
  }

  function saveCopy(
    moduleId: string,
    clientName: string,
    customClientId?: number
  ) {
    const clientIdValue = customClientId ? customClientId : clientId;

    return axios
      .get(endpoints.MODULES.COPY, {
        params: {
          moduleId,
          clientId: clientIdValue,
          clientName,
        },
      })
      .then((response: any) => {
        notificationComposable.success("module.copied_module_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("module.copied_module_error");
        throw error;
      });
  }

  function goToEditModule(
    moduleId: string,
    moduleType: ModuleTypes | "Project" | null,
    projectId?: string
  ) {
    if (moduleType === "QnA") return;

    const isFromDashboard = !!projectId;

    if (isFromDashboard) {
      return router.push({
        name: "protected.projects.project.module",
        params: {
          moduleId,
          projectId: projectId,
        },
        query: {
          from: "dashboard",
        },
      });
    }

    return router.push({
      name: "protected.projects.project.module",
      params: {
        moduleId,
        projectId: route.params.projectId,
      },
    });
  }

  function saveTopicList(moduleId: string, activeList: Array<AiTopic>) {
    return axios
      .post(endpoints.MODULES.SAVE_TOPIC_LIST, {
        moduleId,
        LastUpdatedByUserId: email,
        activeList,
      })
      .then((response: any) => {
        notificationComposable.success("module.save_topic_list_success");
        return response as Module;
      })
      .catch((err) => {
        notificationComposable.error("module.save_topic_list_error");
        throw err;
      });
  }

  function getAvailableTopics(moduleId: string) {
    return axios
      .get(endpoints.MODULES.AVAILABLE_TOPICS, {
        params: { moduleId, clientId },
      })
      .then((response: any) => response as Array<AiTopic>);
  }

  function getRecentModules() {
    return axios
      .get(endpoints.MODULES.GET_RECENT_MODULES, {
        params: { clientId },
      })
      .then((response: any) => {
        const modules = response as Array<ModuleMostRecent>;
        const contents = [] as Array<ProjectContentItem>;
        _.forEach(modules, (p) => {
          contents.push(generateContentItemModule(p));
        });
        return contents;
      });
  }

  function generateContentItemModule(module: ModuleMostRecent) {
    const moduleType = utilsComposable.getModuleTypeByEnum(module.moduleType);
    const content = {
      displayStatus: "Published",
      id: module.moduleId,
      name: module.moduleName,
      type: moduleType,
      lang: constants.LANGUAGES.EN,
      status: constants.STATE_STATUS.PUBLISHED,
      tags: [],
    } as ProjectContentItem;
    return content;
  }

  function getProjectsByModuleId(moduleId: string) {
    return axios
      .get(endpoints.MODULES.GET_PROJECTS_BY_MODULE, {
        params: { moduleId },
      })
      .then((response: any) => {
        return response as Array<Project>;
      });
  }

  function convertTopicToModule(source: any, topicId: string): Module {
    let topicProperties;
    if (source.modules) {
      topicProperties = source.modules[0].moduleProperties;
    } else {
      topicProperties = null;
    }

    const target: Module = {
      id: source.id,
      moduleId: "",
      moduleProperties: topicProperties,
      displayStatus: source.status,
      status: source.status,
      moduleState: null,
      isPublished: source.isPublished,
      quiz: null,
      topics: [
        {
          topicId: topicId,
        },
      ],
      intake: null,
      presentation: null,
      navigation: null,
      questionModule: null,
      speech: null,
      intro: null,
      outro: null,
      createdDateTime: source.createdDateTime,
      lastUpdatedDateTime: source.lastUpdatedDateTime,
      clientID_lng: source.clientID_lng,
      lang: source.lang,
      estimatedRuntime: source.estimatedRuntime,
      moduleName: source.topicName,
      moduleType_chr: "QnA",
      lastUpdatedByUserId: source.lastUpdatedByUserId,
      tags: [],
    };

    return target;
  }

  function getModuleTopicsUnpublishedStatus(moduleId: string) {
    return axios
      .get(endpoints.MODULES.GET_MODULE_TOPICS_UNPUBLISHED_CHANGES, {
        params: {
          moduleId,
        },
      })
      .then((response: any) => {
        const topics = response as Array<AiTopic>;
        return _.map(topics, (t: AiTopic) => {
          return {
            moduleName: t.topicName,
            moduleId: t.topicId,
            moduleType: "QnA" as ModuleTypes,
          };
        });
      });
  }

  return {
    get,
    getModuleData,
    createModule,
    getModulesList,
    getTimelineModules,
    deleteModule,
    updateName,
    publishModule,
    revertModuleToPublished,
    saveCopy,
    goToEditModule,
    saveTopicList,
    getAvailableTopics,
    getRecentModules,
    getProjectsByModuleId,
    convertTopicToModule,
    makeContentObject,
    getModuleTopicsUnpublishedStatus,
  };
};
