const _import = (path: string) =>
  import(`@/views/protected/projects/${path}.vue`);

export default [
  {
    path: "projects",
    name: "protected.projects",
    component: () => _import("Projects"),
    meta: {
      name: "project.create",
    },
    redirect: {
      name: "protected.projects.dashboard",
    },
    children: [
      {
        path: "",
        name: "protected.projects.dashboard",
        component: () => _import("Dashboard"),
        meta: {
          name: "project.create",
        },
      },
      {
        path: ":projectId",
        name: "protected.projects.project",
        component: () => _import("project/Project"),
        redirect: {
          name: "protected.projects.project.timeline",
        },
        children: [
          {
            path: "",
            name: "protected.projects.project.timeline",
            component: () => _import("project/Timeline"),
          },
          {
            path: "ai-topic/:topicId",
            name: "protected.projects.project.ai_topic",
            component: () => _import("project/AiTopic"),
            meta: {
              name: "aitopics.base",
            },
          },
          {
            path: "module/:moduleId",
            name: "protected.projects.project.module",
            component: () => _import("project/Module"),
            meta: {
              name: "base.module",
            },
          },
          {
            path: "settings/:moduleOrTopicId?",
            name: "protected.projects.project.settings",
            component: () => _import("project/Settings"),
            meta: {
              name: "base.settings",
              disableSuffixParam: true,
            },
          },
        ],
      },
    ],
  },
];
