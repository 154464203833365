export default {
  setDarkMode(state: any, value: boolean) {
    state.darkMode = value;
  },
  setLang(state: any, value: string) {
    state.lang = value;
  },
  setSmallScreen(state: any, value: string) {
    state.isSmallScreen = value;
  },
};
