import { apiConfig } from "./apiConfig";

const getEndpoints = () => {
  const apiUrl = apiConfig.API_URL;
  const qaUrl = apiConfig.API_BACKEND_URL;

  return {
    AUTH: {
      LOGIN: `${apiUrl}/api/Account/Login`,
      LOGOUT: `${apiUrl}/api/Account/Logout`,
      FORGOT_PASSWORD: `${apiUrl}/api/Account/ForgotPassword`,
      CHANGE_PASSWORD: `${apiUrl}/api/Account/ChangePassword`,
      USER_INFO: `${apiUrl}/api/UserInfo/GetLoggedInUserDetail`,
      RESET_PASSWORD: `${apiUrl}/api/Account/ResetPassword`,
      CONFIRM_EMAIL: `${apiUrl}/api/Account/ConfirmEmail`,
      IMPERSONATE_USER: `${apiUrl}/api/Account/ImpersonateUser`,
      REVERT_IMPERSONATION: `${apiUrl}/api/Account/StopImpersonation`,
      USERS: `${apiUrl}/api/Account/Users/Users`,
      SET_USER_AGREEMENT: `${apiUrl}/api/UserInfo/SetUserAgreement`,
      ADD_USER_DETAIL: `${apiUrl}/api/UserInfo/AddUserDetail`,
      GET_SERVICE_STATUS: `${apiUrl}/api/UserInfo/GetProductServiceStatus`,
    },
    ANALYTICS: {
      USERS_PER_DAY: `${apiUrl}/api/Analytics/UsersPerDay`,
      GET_POPULAR_STATS: `${apiUrl}/api/Analytics/getPopularStats`,
      GET_TOP_MODULES: `${apiUrl}/api/Analytics/getTopModules`,
      GET_TOP_PROJECTS: `${apiUrl}/api/Analytics/getTopProjects`,
      GET_FEEDBACK: `${apiUrl}/api/Analytics/getFeedback`,
      GET_OS_BREAKDOWN: `${apiUrl}/api/Analytics/getOSBreakdown`,
      GET_QUESTION_INFO: `${apiUrl}/api/Analytics/getQuestionModuleInfo`,
      GET_PRESENTATION_INFO: `${apiUrl}/api/Analytics/getPresentationInfo`,
      GET_QUIZ_INFO: `${apiUrl}/api/Analytics/getQuizInfo`,
      GET_CODE_DETAILS: `${apiUrl}/api/Analytics/getCodeDetails`,
      GET_MULTIPLE_CODE_DETAILS: `${apiUrl}/api/Analytics/getMultipleCodeDetails`,
      GET_FORM_LIST: `${apiUrl}/api/Analytics/getFormList`,
      GET_FORM_INFO: `${apiUrl}/api/Analytics/getFormInfo`,
      GET_SESSION_LIST: `${apiUrl}/api/Analytics/getSessionList`,
      GET_SESSION_INFO: `${apiUrl}/api/Analytics/getSessionInfo`,
      GET_ALL_DATA: `${apiUrl}/api/Analytics/getAllData`,
    },
    USERS: {
      LIST_ALL: `${apiUrl}/api/User/Users`,
      LIST: `${apiUrl}/api/User/GetAllClientUsers`,
      LIST_ALL_PAGINATION: `${apiUrl}/api/User/GetAllUsers`,
      ADD: `${apiUrl}/api/User/AddUser`,
      USER_INFO: `${apiUrl}/api/User/GetUser`,
      UPDATE_INFO: `${apiUrl}/api/User/UpdateUser`,
      RESET_PASSWORD: `${apiUrl}/api/User/ResetPassword`,
      DELETE: `${apiUrl}/api/User/DeleteUser`,
    },
    CLIENTS: {
      LIST: `${apiUrl}/api/Client/GetClient`,
      POST_PUT: `${apiUrl}/api/Client/PostPutClient`,
      DELETE: `${apiUrl}/api/Client/DeleteClient`,
    },
    ROLES: {
      LIST: `${apiUrl}/api/Role/GetRoleByClientId`,
    },
    PROJECTS: {
      GET: `${apiUrl}/api/Project/GetProject`,
      CREATE_UPDATE: `${apiUrl}/api/Project/PutPostProject`,
      LIST: `${apiUrl}/api/Project/GetProjectAndModuleCount`,
      DELETE: `${apiUrl}/api/Project/DeleteProject`,
      SEARCH: `${apiUrl}/api/Project/DeleteProject`,
      UPDATE_MODULE_LIST: `${apiUrl}/api/Project/UpdateModules`,
      COPY: `${apiUrl}/api/Project/CopyProject`,
      PUBLISH: `${apiUrl}/api/Project/PublishProject`,
      REVERT_TO_PUBLISHED: `${apiUrl}/api/Project/RevertToPublished`,
      SET_INTRO_VIDEO: `${apiUrl}/api/Project/SetIntroVideoURL`,
      CLEAR_PRONUNCIATIONS: `${apiUrl}/api/Project/ClearPronunciation`,
      GET_MOST_RECENT: `${apiUrl}/api/Project/GetMostRecent`,
      GET_PROJECT_MODULES_UNPUBLISHED_CHANGES: `${apiUrl}/api/Project/ProjectModulesUnpublishedsChanges`,
    },
    CODES: {
      CREATE: `${apiUrl}/api/Code/AddNewCode`,
      LIST: `${apiUrl}/api/Code/GetAllCodes`,
      TOP_LIST: `${apiUrl}/api/Analytics/getTopCodes`,
      RECENT_LIST: `${apiUrl}/api/Analytics/getRecentCodes`,
      ACTIVATE_DESACTIVATE: `${apiUrl}/api/Code/ActivateDeactivateCode`,
      COPY: `${apiUrl}/api/Code/GetDuplicateCodeDetails`,
      DELETE: `${apiUrl}/api/Code/Delete`,
      SEND_SMS: `${apiUrl}/api/Code/SendCodesOnSms`,
      SEND_EMAIL: `${apiUrl}/api/Code/SendCodesOnMail`,
      UPDATE_TAG: `${apiUrl}/api/Code/UpdateCodeTags`,
      UPDATE_CODE: `${apiUrl}/api/Code/UpdateCode`,
      GET_DETAILS: `${apiUrl}/api/Code/GetCode`,
      GET_BY_CODE_NAME: `${apiUrl}/api/Code/GetCodesByCodeName`,
      GET_CODES_BY_PROJECT: `${apiUrl}/api/Code/GetCodesByProject`,
      GET_CODES_BY_MODULE: `${apiUrl}/api/Code/GetCodesByModule`,
      GET_CODES_BY_TOPIC: `${apiUrl}/api/Code/GetCodesByTopic`,
      SHARE_CODE: `${apiUrl}/api/Code/ShareCode`,
    },
    TAGS: {
      LIST: `${apiUrl}/api/Tags/GetAllTags`,
      CREATE: `${apiUrl}/api/Tags/AddUserTags`,
      ADD_EDIT_TAGS_MODULE: `${apiUrl}/api/Module/addEditTags`,
      UPDATE_TAGS: `${apiUrl}/api/Topic/UpdateTopicTags`,
    },
    MODULES: {
      GET: `${apiUrl}/api/Module/GetModule`,
      GET_MODULE_DATA: `${apiUrl}/api/Module/GetModuleData`,
      CREATE: `${apiUrl}/api/Module/PostModule`,
      DELETE: `${apiUrl}/api/Module/Delete`,
      TIMELINE_MODULES: `${apiUrl}/api/Module/GetTimelineModules`,
      GET_ALL_MODULELIST: `${apiUrl}/api/Module/GetAllAvailableModules`,
      UPDATE_NAME: `${apiUrl}/api/Module/UpdateModuleName`,
      SAVE_TOPIC_LIST: `${apiUrl}/api/Module/SaveActiveTopicList`,
      AVAILABLE_TOPICS: `${apiUrl}/api/Module/GetAvailableTopics`,
      COPY: `${apiUrl}/api/Module/CopyModule`,
      PUBLISH: `${apiUrl}/api/Module/PublishModule`,
      REVERT_TO_PUBLISHED: `${apiUrl}/api/Module/RevertToPublished`,
      GET_RECENT_MODULES: `${apiUrl}/api/Module/GetMostRecent`,
      GET_PROJECTS_BY_MODULE: `${apiUrl}/api/Module/GetProjectsByModuleId`,
      GET_MODULE_TOPICS_UNPUBLISHED_CHANGES: `${apiUrl}/api/Module/GetModuleTopicsUnpublishedChanges`,
    },
    QUIZ: {
      GET: `${apiUrl}/api/Quiz/GetQuiz`,
      CREATE: `${apiUrl}/api/Quiz/PutPostQuizSlide`,
      DELETE: `${apiUrl}/api/Quiz/DeleteSlide`,
      SORT: `${apiUrl}/api/Quiz/SortSlides`,
      REVERT_SLIDE: `${apiUrl}/api/Quiz/RevertSlide`,
    },
    PRESENTATION: {
      IMPORT: `${apiUrl}/api/Presentation/ImportPresentation`,
      IMPORT_URL: `${apiUrl}/api/Presentation/ImportPresentationByID`,
      CREATE_SLIDE: `${apiUrl}/api/Presentation/PutPostSlideData`,
      DELETE_SLIDE: `${apiUrl}/api/Presentation/DeleteSlide`,
      GET: `${apiUrl}/api/Presentation/GetPresentation`,
      SORT: `${apiUrl}/api/Presentation/SortSlides`,
      REVERT_SLIDE: `${apiUrl}/api/Presentation/RevertSlide`,
    },
    SPEECH: {
      UPDATE: `${apiUrl}/api/Module/UpdateSpeechModule`,
    },
    INTAKE: {
      GET: `${apiUrl}/api/Intake/GetIntake`,
      CREATE_UPDATE_ENTRY: `${apiUrl}/api/Intake/PutPostIntakeEntry`,
      CREATE_UPDATE: `${apiUrl}/api/Intake/PutPostIntakeModule`,
      DELETE_ENTRY: `${apiUrl}/api/Intake/DeleteEntry`,
      SORT: `${apiUrl}/api/Intake/SortIntake`,
      REVERT_ENTRY: `${apiUrl}/api/Intake/RevertEntry`,
    },
    NAVIGATION: {
      GET: `${apiUrl}/api/Navigation/GetNavigation`,
      CREATE_UPDATE: `${apiUrl}/api/Navigation/PutPostNavigationModule`,
    },
    TOPICS: {
      GET: `${apiUrl}/api/Topic/GetTopic`,
      GET_ALL: `${apiUrl}/api/Topic/GetAllTopics`,
      GET_TOPICS_BY_ID: `${apiUrl}/api/Topic/GetTopics`,
      CREATE: `${apiUrl}/api/Topic/AddTopic`,
      PUBLISH: `${apiUrl}/api/Topic/PublishTopic`,
      REVERT_TO_PUBLISHED: `${apiUrl}/api/Topic/RevertToPublished`,
      GET_MARKUP: `${apiUrl}/api/Markup/GetMarkupData`,
      PUT_POST_MARKUP: `${apiUrl}/api/Markup/PutPostMarkup`,
      GET_RECORDS: `${apiUrl}/api/Record/GetRecords`,
      PUT_POST_RECORD: `${apiUrl}/api/Record/PutPostRecord`,
      DELETE_RECORD: `${apiUrl}/api/Record/Delete`,
      RENAME_TOPIC: `${apiUrl}/api/Topic/UpdateTopicName`,
      IMPORT_CSV: `${apiUrl}/api/Topic/ImportFromCSV`,
      COPY_TOPIC: `${apiUrl}/api/Topic/CopyTopic`,
      DETELE_ALL: `${apiUrl}/api/Topic/DeleteAll`,
      COMPARE_RECORDS: `${apiUrl}/api/Topic/CompareRecords`,
      COMPARE_RECORDS_WITH_PINECONE: `${apiUrl}/api/Topic/CompareRecordsWithPinecone`,
      IMPORT_FROM_SPREADSHEET: `${apiUrl}/api/Topic/ImportFromSpreadsheet`,
      QUERY_TOPIC: `${apiUrl}/api/Topic/QueryTopic`,
      GET_TESTS: `${apiUrl}/api/Topic/GetTestsForTopic`,
      REQUEST_TEST: `${apiUrl}/api/Topic/RequestTest`,
      GET_TEST: `${apiUrl}/api/Topic/GetTest`,
      GET_PROJECTS_BY_TOPIC_ID: `${apiUrl}/api/Topic/GetProjectsByTopicId`,
      EXPORT_TOPIC: `${apiUrl}/api/Topic/ExportTopic`,
      UPDATE_RECORD_INDEX: `${apiUrl}/api/Record/UpdateRecordIndex`,
    },
    ASSETS: {
      UPLOAD_CLIENT: `${apiUrl}/api/Upload/UploadClientFile`,
    },
    UPLOAD: {
      LIST_BLOBS: `${apiUrl}/api/Upload/ListBlobs`,
      LIST_SLIDE_BLOBS: `${apiUrl}/api/Upload/ListSlideBlobs`,
      GET_VIDEOS: `${apiUrl}/api/Upload/GetTagVideos`,
      GET_VIDEO_BY_ID: `${apiUrl}/api/Upload/GetSingleVideo`,
      GET_VIDEO_PICS: `${apiUrl}/api/Upload/GetVideoPics`,
      GET_ANIMATIONS: `${apiUrl}/api/Upload/GetAnimations`,
      UPLOAD_FILE: `${apiUrl}/api/Upload/UploadFile`,
      UPLOAD_ANIMATIONS: `${apiUrl}/api/Upload/UploadAnimations`,
      UPLOAD_FILE_BY_FOLDER: `${apiUrl}/api/Upload/UploadFileByFolder`,
      UPLOAD_IMAGE_64: `${apiUrl}/api/Upload/UploadImageBase64`,
      UPLOAD_VIMEO: `${apiUrl}/api/Upload/UploadVimeo`,
      GET_FILE_BY_FOLDER: `${apiUrl}/api/Upload/GetFileByFolder`,
      UPLOAD_BACKGROUND: `${apiUrl}/api/Upload/UploadBackground`,
      UPLOAD_QRCODE: `${apiUrl}/api/Upload/UploadQRCode`,
      MOVE_PRESENTATION: `${apiUrl}/api/Upload/movePresentationSlides`,
      DELETE_FILE: `${apiUrl}/api/Upload/DeleteFile`,
      DELETE_VIDEO: `${apiUrl}/api/Upload/Deletevideo`,
    },
    AVATARS: {
      LIST_ALL: `${apiUrl}/api/Avatar/GetAllAvatars`,
      LIST: `${apiUrl}/api/Avatar/GetAvatar`,
      SET_VISIBLE: `${apiUrl}/api/Avatar/SetVisibility`,
      OTHER_LINES: `${apiUrl}/api/AgentLines/GetAllAgentLinesData`,
      TEXT_SPEECH: `${qaUrl}/api/TextToSpeech`,
    },
    USER_ACCESS: {
      GET_USER_CLIENT_LIST: `${apiUrl}/api/UserAccess/GetUserAccessList`,
      GRANT_CLIENT_ACCESS: `${apiUrl}/api/UserAccess/GrantClientAccess`,
      REVOKE_CLIENT_ACCESS: `${apiUrl}/api/UserAccess/RevokeClientAccess`,
    },
  };
};

export { getEndpoints };
