export default {
  setToken(state: any, token: string) {
    state.token = token;
  },
  setInfo(state: any, info: object) {
    state.info = info;
  },
  setOriginalInfo(state: any, info: object) {
    state.originalInfo = info;
  },
  setClientAccess(state: any, info: object) {
    state.clientAccess = info;
  },
  setDarkMode(state: any, value: boolean) {
    state.darkMode = value;
  },
  setImpersonated(
    state: any,
    obj: {
      type: string | null;
      id: string | number | null;
      originalId: string | number | null;
      token: string | null;
      clientName: string | null;
    }
  ) {
    state.impersonate.type = obj.type;
    if (!obj.type) return;
    state.impersonate.id = obj.id;
    state.impersonate.originalId = obj.originalId;
    state.impersonate.token = obj.token;
    state.impersonate.clientName = obj.clientName;
  },
  setClientId(state: any, value: number) {
    state.info.clientId = value;
  },
  setClientName(state: any, value: string) {
    state.info.clientName = value;
  },
  setUserAgreement(state: any) {
    state.info.acceptedUserAgreement = true;
  },
};
