import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "side-notifications" }
const _hoisted_2 = { class: "side-notifications__header" }
const _hoisted_3 = { class: "side-notifications__body" }
const _hoisted_4 = { class: "side-notifications__notification" }
const _hoisted_5 = { class: "side-notifications__time" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "side-notifications__empty"
}
const _hoisted_8 = { class: "side-notifications__notification" }
const _hoisted_9 = { class: "side-notifications__time" }
const _hoisted_10 = ["href"]

import { computed, onMounted } from "vue";
import Accordion from "../theme/Accordion.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  notifications,
  useLocalNotifications,
} from "@/composables/notificationsLocal";


const __default__ = {
  name: "side-notifications",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

dayjs.extend(relativeTime);
onMounted(() => {
  useLocalNotifications().getNotifications();
});

const isNewNotification = (time: any) => {
  const fiveMinutesAgo = dayjs().subtract(1, "day");
  return dayjs(time).isAfter(fiveMinutesAgo);
};

const newNotifications = computed(() =>
  notifications.value.filter((notification) =>
    isNewNotification(notification.time)
  )
);
const olderNotifications = computed(() =>
  notifications.value.filter(
    (notification) => !isNewNotification(notification.time)
  )
);

return (_ctx: any,_cache: any) => {
  const _component_hia_icon = _resolveComponent("hia-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t("notification.title")) + " ", 1),
      _createVNode(_component_hia_icon, {
        size: "18",
        color: "white",
        name: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(useLocalNotifications)().closeSideNotifications()))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (newNotifications.value.length >= 1)
        ? (_openBlock(), _createBlock(Accordion, { key: 0 }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("notification.today")), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_TransitionGroup, {
                tag: "div",
                appear: "",
                name: "slide"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newNotifications.value, (notification, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["side-notifications__content", { leave: !notification.active }])
                    }, [
                      _createVNode(_component_hia_icon, {
                        size: "40",
                        name: notification.icon,
                        color: 
                  notification.type === 'error'
                    ? 'red'
                    : notification.type === 'success'
                    ? 'green'
                    : notification.type === 'warning'
                    ? 'orange'
                    : 'black'
                
                      }, null, 8, ["name", "color"]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(notification.title)), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("p", null, _toDisplayString(_unref(dayjs)(notification.time).fromNow()), 1),
                          _createElementVNode("p", null, _toDisplayString(notification.type), 1)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["side-notifications__buttons", { noView: !notification.link }])
                      }, [
                        (notification.link)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: notification.link
                            }, "View", 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        _createVNode(_component_hia_icon, {
                          size: "18",
                          name: "delete",
                          onClick: ($event: any) => (
                    _unref(useLocalNotifications)().deleteNotificationById(
                      notification.id
                    )
                  )
                        }, null, 8, ["onClick"])
                      ], 2)
                    ], 2))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, "No new notifications")),
      (olderNotifications.value.length >= 1)
        ? (_openBlock(), _createBlock(Accordion, { key: 2 }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("notification.older")), 1)
            ]),
            content: _withCtx(() => [
              _createVNode(_TransitionGroup, {
                tag: "div",
                appear: "",
                name: "slide"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(olderNotifications.value, (notification, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["side-notifications__content", { leave: !notification.active }])
                    }, [
                      _createVNode(_component_hia_icon, {
                        size: "40",
                        name: notification.icon,
                        color: 
                  notification.type === 'error'
                    ? 'red'
                    : notification.type === 'success'
                    ? 'green'
                    : notification.type === 'warning'
                    ? 'orange'
                    : 'black'
                
                      }, null, 8, ["name", "color"]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(notification.title)), 1),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("p", null, _toDisplayString(_unref(dayjs)(notification.time).fromNow()), 1),
                          _createElementVNode("p", null, _toDisplayString(notification.type), 1)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["side-notifications__buttons", { noView: !notification.link }])
                      }, [
                        (notification.link)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: notification.link
                            }, "View", 8, _hoisted_10))
                          : _createCommentVNode("", true),
                        _createVNode(_component_hia_icon, {
                          size: "18",
                          name: "delete",
                          onClick: ($event: any) => (
                    _unref(useLocalNotifications)().deleteNotificationById(
                      notification.id
                    )
                  )
                        }, null, 8, ["onClick"])
                      ], 2)
                    ], 2))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("button", {
      class: "side-notifications__dismiss",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(useLocalNotifications)().clearNotification()))
    }, " Dismiss all ", 512), [
      [_vShow, _unref(notifications).length]
    ])
  ]))
}
}

})