import { Tag, CreateTag } from "@/models/tag";
import { inject } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import _ from "lodash";

export const useTag = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const store = useStore();

  const { email } = store.state.Auth.originalInfo;

  function getList() {
    return axios
      .get(endpoints.TAGS.LIST)
      .then((response: any) => {
        const tags: Array<Tag> = [];

        _.forEach(response.tags, (item) => {
          _.forEach(item.tagName, (tag) => {
            tags.push({
              id: tag.id,
              name: tag.name,
              tagType: item.tagType,
            });
          });
        });
        return tags;
      })
      .catch((error) => {
        throw error;
      });
  }

  function createTag(payload: Array<string>) {
    return axios
      .post(endpoints.TAGS.CREATE, { tags: payload })
      .then((response: any) => {
        return response as CreateTag;
      });
  }

  function addEditTags(tags: Array<Tag>, moduleId: string) {
    return axios
      .post(endpoints.TAGS.ADD_EDIT_TAGS_MODULE, {
        tags,
        moduleId,
        email,
      })
      .then((response) => response);
  }
  function updateTags(tags: Array<Tag>, topicId: string) {
    return axios
      .patch(endpoints.TAGS.UPDATE_TAGS, {
        tags,
        topicId,
      })
      .then((response) => response);
  }

  return {
    getList,
    createTag,
    addEditTags,
    updateTags,
  };
};
