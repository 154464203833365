import constants from "@/static/ts/constants";
import store from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

const _import = (path: string) => import(`@/views/protected/admin/${path}.vue`);

export default [
  {
    path: "admin",
    name: "protected.admin",
    component: () => _import("Admin"),
    meta: {
      name: "admin.base",
      roles: [constants.ROLES.ADMIN, constants.ROLES.CLIENT_ADMIN],
    },
    redirect: {
      name: "protected.admin.dashboard",
    },
    children: [
      {
        path: "",
        name: "protected.admin.dashboard",
        component: () => _import("Dashboard"),
        meta: {
          name: "admin.base",
        },
      },
      {
        path: "users/:clientId?",
        name: "protected.admin.users",
        component: () => _import("users/Users"),
        meta: {
          name: "admin.users",
        },
        redirect: {
          name: "protected.admin.users.list",
        },
        children: [
          {
            path: "",
            name: "protected.admin.users.list",
            component: () => _import("users/List"),
            meta: {
              name: "User List",
            },
          },
          {
            path: "form/:userId?",
            name: "protected.admin.users.form",
            component: () => _import("users/Form"),
            meta: {
              name: "User Details",
            },
          },
        ],
      },
      {
        path: "clients",
        name: "protected.admin.clients",
        component: () => _import("clients/Clients"),
        meta: {
          name: "Clients",
        },
        redirect: {
          name: "protected.admin.clients.list",
        },
        children: [
          {
            path: "",
            name: "protected.admin.clients.list",
            component: () => _import("clients/List"),
            meta: {
              name: "List",
              roles: [constants.ROLES.ADMIN],
            },
          },
          {
            path: ":clientId",
            name: "protected.admin.clients.edit",
            component: () => _import("clients/Edit"),
            meta: {
              name: "Client Details",
              roles: [constants.ROLES.ADMIN, constants.ROLES.CLIENT_ADMIN],
            },
            beforeEnter: (
              to: RouteLocationNormalized,
              from: RouteLocationNormalized,
              next: NavigationGuardNext
            ) => {
              const userInfo = store.getters["Auth/userInfo"];
              if (
                userInfo.role === constants.ROLES.ADMIN ||
                +to.params.clientId === +userInfo.clientId
              ) {
                next();
              } else {
                next({ name: "protected" });
              }
            },
          },
        ],
      },
      {
        path: "copy-content",
        name: "protected.admin.copy-content",
        component: () => _import("CopyContent"),
        meta: {
          name: "admin.copy_content",
        },
      },
      {
        path: "export-topic",
        name: "protected.admin.export-topic",
        component: () => _import("ExportTopic"),
        meta: {
          name: "admin.export_topic",
        },
      },
      {
        path: "client-access/:clientId?/:userId?",
        name: "protected.admin.client-access",
        component: () => _import("ClientAccess"),
        meta: {
          name: "admin.client_access",
        },
      },
      {
        path: "search-code",
        name: "protected.admin.search-code",
        component: () => _import("SearchCode"),
        meta: {
          roles: [constants.ROLES.ADMIN],
          name: "admin.search_code",
        },
      },
    ],
  },
];
