<template>
  <div
    class="webgl"
    :class="{ webgl__inactive: !active }"
    :style="computedStyle"
  >
    <div class="webgl__iframe" :style="iframeStyle">
      <div class="webgl__box">
        <div class="webgl__cover" v-if="webglComposable.audioMode.value">
          <p v-html="$t('webgl.browser_not_supported')" />
          <div class="webgl__browser-icons">
            <a href="https://www.google.com/chrome/" target="_blank">
              <img
                src="../../../assets/images/chrome.png"
                alt="Google Chrome"
              />
              <p>Google Chrome</p>
            </a>
            <a href="https://www.mozilla.org/firefox/new/" target="_blank">
              <img
                src="../../../assets/images/firefox.png"
                alt="Mozilla Firefox"
              />
              <p>Mozilla Firefox</p>
            </a>
          </div>
        </div>

        <div
          class="webgl__cover"
          v-else-if="!initialized || (!agentLoaded && !loadingError)"
        >
          <hia-progress-circular xl color="primary" />
          <p>
            {{ $t("project.loading_preview") }}
          </p>
        </div>
        <div
          class="webgl__cover"
          v-else-if="!initialized || (!agentLoaded && loadingError)"
        >
          <p>
            {{ $t("project.loading_preview_error") }}
          </p>
          <hia-btn primary @click="reloadSlide()">
            {{ $t("project.loading_preview_error_button") }}
          </hia-btn>
        </div>

        <iframe
          :src="iframeUrl"
          width="100%"
          height="100%"
          allow="microphone;autoplay"
          ref="iframeEl"
          v-if="!webglComposable.audioMode.value"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "webgl-component",
};
</script>

<script lang="ts" setup>
import _ from "lodash";
import { inject, onMounted, ref, computed } from "vue";
import { useEvent, useWebgl } from "@/composables";
import { WebglMessagePost } from "@/models/webgl";
import { useRoute } from "vue-router";

const events = inject("events") as EventsEnum;
const apiConfig = inject("apiConfig") as ApiConfigEnum;

const eventComposable = useEvent();
const webglComposable = useWebgl();
const route = useRoute();

let elementId = "module-preview-box";

const scrollTop = ref<number>(0);
const iframeEl = ref<HTMLIFrameElement>();
const active = ref(false);
const style = ref({
  top: "0px",
  left: "0px",
  width: "1024px",
  height: "768px",
  zIndex: 30,
});

const iframeStyle = ref({
  width: "1024px",
  height: "768px",
});

const iframeUrl = ref(`${apiConfig.WEBGL_URL}/?dynamicMode=true`);

const initialized = computed(() => webglComposable.initialized.value);
const agentLoaded = computed(() => webglComposable.agentLoaded.value);
const loadingError = ref(false);
const computedStyle = computed(() => {
  const _style = _.cloneDeep(style.value);
  _style.top = `${+_style.top.replace("px", "") - scrollTop.value}px`;
  return _style;
});

function setStyle() {
  const box = document.getElementById(elementId);
  if (!box || !box.firstChild) {
    setTimeout(() => setStyle());
    return;
  }

  const positionEl = box.firstChild as HTMLElement;
  style.value.left = `${positionEl.offsetLeft}px`;
  style.value.top = `${positionEl.offsetTop}px`;
  style.value.width = `${box.offsetWidth}px`;
  style.value.height = `${box.offsetHeight}px`;

  const scale = Math.min(box.offsetWidth / 1280, box.offsetHeight / 720);
  iframeStyle.value.width = `${1280 * scale - 20}px`;
  iframeStyle.value.height = `${720 * scale - 20}px`;
}

function show(obj: { divId: undefined | string; zIndex: undefined | number }) {
  const routes = _.map(route.matched, (r) => r.name);

  if (
    !routes.includes("protected.dashboard") &&
    !routes.includes("protected.projects.project.module") &&
    !routes.includes("protected.projects.project.ai_topic")
  ) {
    return;
  }

  style.value.zIndex = obj.zIndex || 30;
  elementId = obj.divId || "module-preview-box";
  setTimeout(() => {
    setStyle();
    active.value = true;
    toggleScrollEvt();
  });
  checkAgentLoaded();
}

function hide() {
  active.value = false;
  toggleScrollEvt(true);
}

function postMessage(message: WebglMessagePost) {
  if (iframeEl.value && iframeEl.value.contentWindow) {
    console.log("POST EVENT", message);
    iframeEl.value.contentWindow.postMessage(message, "*");
  }
}

function onScroll(evt: any) {
  scrollTop.value = evt.target.scrollTop;
}

function toggleScrollEvt(remove = false) {
  const parentScroll = document.getElementsByClassName("protected__holder")[0];
  if (parentScroll) {
    parentScroll[remove ? "removeEventListener" : "addEventListener"](
      "scroll",
      onScroll
    );
    scrollTop.value = parentScroll.scrollTop;
  }
}

const checkAgentLoaded = () => {
  setTimeout(() => {
    if (!agentLoaded.value) {
      loadingError.value = true;
    }
  }, 60000); // 60000 milliseconds = 1 minute
};

function reloadSlide() {
  webglComposable.hide();
  webglComposable.show();
  webglComposable.init();
  loadingError.value = false;
}

onMounted(() => {
  webglComposable.init();

  eventComposable.on(events.WEBGL_HIDE, hide);
  eventComposable.on(events.WEBGL_SHOW, show);
  eventComposable.on(events.WEBGL_RESIZE, setStyle);
  checkAgentLoaded();
  window.addEventListener("resize", setStyle);

  if (!webglComposable.audioMode.value) {
    eventComposable.on(events.WEBGL_POST, postMessage);
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.action) {
          console.log("NEW EVENT", event.data);
          webglComposable.onReceiveEvt(event.data);
        }
      },
      false
    );
  }
});
</script>

<style lang="scss">
.webgl {
  @apply fixed
  justify-center
  items-center
  pointer-events-none
  flex;
  &__inactive {
    z-index: -10 !important;
    left: -20000px !important;
  }
  &__box {
    @apply w-full
    h-full
    relative
    pointer-events-auto;
  }
  &__cover {
    @apply w-full
    h-full
    bg-gray4
    flex
    items-center
    justify-center
    flex-col
    gap-8
    text-sm
    text-gray35
    absolute
    z-10;
    p {
      @apply text-center;
    }
  }
  &__browser-icons {
    @apply flex justify-center mt-4;
    a {
      @apply mx-4 mb-3 flex flex-col items-center;
      p {
        @apply mt-4;
      }
    }
  }
}
</style>
