import { AiTopicPineconeInstance } from "@/models/aiTopic";
import { AuthUserRoles } from "@/models/auth";
import { ModuleTextToSpeechLayerTypes } from "@/models/module";
import {
  ModuleIntakeQuestionType,
  ModuleIntakeQuestionDiagramMarkerTypes,
} from "@/models/moduleIntake";
import { ProjectContentTypes } from "@/models/project";
import { SlidePronunciationsPhonemeTypes } from "@/models/slide";
import { Language, StateStatus } from "@/models/utils";

export default {
  CONTENT_TYPES: {
    QUIZ: "Quiz",
    QNA: "QnA",
    INTAKE: "Intake",
    SPEECH: "Speech",
    NAVIGATION: "Navigation",
    PRESENTATION: "Presentation",
    AI_TOPIC: "AiTopic",
  } as { [key: string]: ProjectContentTypes },
  STATE_STATUS: {
    UNPUBLISHED: StateStatus.Unpublished,
    UNPUBLISHED_CHANGES: StateStatus.UnpublishedChanges,
    PUBLISHED: StateStatus.Published,
    PUBLISHING: StateStatus.Publishing,
  } as { [key: string]: StateStatus },
  MIME_TYPES: {
    PNG: "image/png",
    CSV: "text/csv",
    GIF: "image/gif",
    JPEG: "image/jpeg",
    MP3: "audio/mp3",
    MPEG: "audio/mpeg",
    MP4: "video/mp4",
    MP4V: "video/x-m4v",
    PDF: "application/pdf",
    PPT: "application/vnd.ms-powerpoint",
    PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  SLIDE_TYPES: {
    PPT: "ppt",
    PPTX: "pptx",
  },
  MODULE_INTAKE_QUESTION: {
    TYPES: {
      TEXT_ENTRY: "Text_Entry",
      TWO_CHOICE: "Two_Choice",
      NUMBER_RANGE: "Number_Range",
      UPLOAD_MEDIA: "Upload_Media",
      MULTIPLE_CHOICE: "Multiple_Choice",
      DIAGRAM: "Diagram",
    } as { [key: string]: ModuleIntakeQuestionType },
    MARKER_TYPES: {
      MARKER_HAND: "Marker_Hand",
      MARKER_ARROWS: "Marker_Arrows",
      MARKER_FOCUS: "Marker_Focus",
      MARKER_TARGET: "Marker_Target",
      MARKER_TARGATELINE: "Marker_TargateLine",
    } as { [key: string]: ModuleIntakeQuestionDiagramMarkerTypes },
  },
  TEXT_TO_SPEECH_LAYERS: {
    PAUSE: "pause",
    PRONUNCIATION: "pronunciation",
    ANIMATION: "animation",
    MEDIA: "media",
  } as { [key: string]: ModuleTextToSpeechLayerTypes },
  ROLES: {
    ADMIN: "HIA Administrator",
    USER: "Client User",
    CLIENT_ADMIN: "Client Administrator",
  } as { [key: string]: AuthUserRoles },
  PHONEMES: {
    IPA: "ipa",
    SAPI: "sapi",
    UPS: "ups",
  } as { [key: string]: SlidePronunciationsPhonemeTypes },
  LANGUAGES: {
    EN: Language.en,
    ES: Language.es,
  } as { [key: string]: Language },
  PINECONE: {
    STAGING: "Staging",
    LIVE: "Live",
  } as { [key: string]: AiTopicPineconeInstance },
  ANALYTICS: {
    LOW_THRESHOLD: 50,
    HIGH_THRESHOLD: 80,
  },
  MAX_FILE_SIZE: 786432000,
};
