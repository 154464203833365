import { ref, computed } from "vue";
import dayjs from "dayjs";

export const notifications = ref<Array<any>>([]);
export const closeNotifications = ref(true);
export const showNotifications = computed(() =>
  notifications.value.length ? true : false
);

export const useLocalNotifications = () => {
  function getNotifications() {
    const savedNotifications = JSON.parse(
      localStorage.getItem("notifications") as string
    );
    if (savedNotifications && Array.isArray(savedNotifications)) {
      notifications.value = savedNotifications;
    }
  }

  function saveNotification(title: string, type: string, icon?: string) {
    const storedNotifications = JSON.parse(
      localStorage.getItem("notifications") || "[]"
    );
    const maxId = storedNotifications.reduce(
      (max: any, notification: any) => Math.max(max, notification.id),
      0
    );
    const nextNotificationId = maxId + 1;

    const newNotification = {
      id: nextNotificationId,
      title: title,
      icon: icon || type,
      time: dayjs(),
      type: type,
      link:
        window.location.pathname.includes("dashboard") ||
        window.location.pathname == "/projects"
          ? null
          : window.location.pathname,
      active: true,
    };
    if (!notifications.value) {
      notifications.value == newNotification;
    } else {
      notifications.value.push(newNotification);
    }

    localStorage.setItem("notifications", JSON.stringify(notifications.value));
    getNotifications();
  }

  function deleteNotificationById(id: number) {
    const notificationToDelete = notifications.value.find(
      (notification) => notification.id === id
    );

    if (notificationToDelete) {
      notificationToDelete.active = false;

      localStorage.setItem(
        "notifications",
        JSON.stringify(notifications.value)
      );

      setTimeout(() => {
        const index = notifications.value.findIndex(
          (notification) => notification.id === id
        );
        if (index !== -1) {
          notifications.value.splice(index, 1);
          localStorage.setItem(
            "notifications",
            JSON.stringify(notifications.value)
          );
          getNotifications();
        }
      }, 1000);
    }
  }

  function clearNotification() {
    localStorage.setItem("notifications", JSON.stringify([]));
    getNotifications();
  }

  function closeSideNotifications() {
    closeNotifications.value = !closeNotifications.value;
  }

  return {
    useLocalNotifications,
    saveNotification,
    getNotifications,
    clearNotification,
    closeSideNotifications,
    deleteNotificationById,
  };
};
