<template>
  <div class="side-notifications">
    <div class="side-notifications__header">
      {{ $t("notification.title") }}
      <hia-icon
        size="18"
        color="white"
        name="close"
        @click="useLocalNotifications().closeSideNotifications()"
      ></hia-icon>
    </div>
    <div class="side-notifications__body">
      <Accordion v-if="newNotifications.length >= 1">
        <template v-slot:title>{{ $t("notification.today") }}</template>
        <template v-slot:content>
          <transition-group tag="div" appear name="slide">
            <div
              v-for="(notification, index) in newNotifications"
              :key="index"
              class="side-notifications__content"
              :class="{ leave: !notification.active }"
            >
              <hia-icon
                size="40"
                :name="notification.icon"
                :color="
                  notification.type === 'error'
                    ? 'red'
                    : notification.type === 'success'
                    ? 'green'
                    : notification.type === 'warning'
                    ? 'orange'
                    : 'black'
                "
              />
              <div class="side-notifications__notification">
                <p>{{ $t(notification.title) }}</p>
                <div class="side-notifications__time">
                  <p>{{ dayjs(notification.time).fromNow() }}</p>
                  <p>{{ notification.type }}</p>
                </div>
              </div>
              <div
                class="side-notifications__buttons"
                :class="{ noView: !notification.link }"
              >
                <a :href="notification.link" v-if="notification.link">View</a>
                <hia-icon
                  size="18"
                  name="delete"
                  @click="
                    useLocalNotifications().deleteNotificationById(
                      notification.id
                    )
                  "
                ></hia-icon>
              </div>
            </div>
          </transition-group>
        </template>
      </Accordion>
      <div v-else class="side-notifications__empty">No new notifications</div>

      <Accordion v-if="olderNotifications.length >= 1">
        <template v-slot:title>{{ $t("notification.older") }}</template>

        <template v-slot:content>
          <transition-group tag="div" appear name="slide">
            <div
              v-for="(notification, index) in olderNotifications"
              :key="index"
              class="side-notifications__content"
              :class="{ leave: !notification.active }"
            >
              <hia-icon
                size="40"
                :name="notification.icon"
                :color="
                  notification.type === 'error'
                    ? 'red'
                    : notification.type === 'success'
                    ? 'green'
                    : notification.type === 'warning'
                    ? 'orange'
                    : 'black'
                "
              />
              <div class="side-notifications__notification">
                <p>{{ $t(notification.title) }}</p>
                <div class="side-notifications__time">
                  <p>{{ dayjs(notification.time).fromNow() }}</p>
                  <p>{{ notification.type }}</p>
                </div>
              </div>
              <div
                class="side-notifications__buttons"
                :class="{ noView: !notification.link }"
              >
                <a :href="notification.link" v-if="notification.link">View</a>
                <hia-icon
                  size="18"
                  name="delete"
                  @click="
                    useLocalNotifications().deleteNotificationById(
                      notification.id
                    )
                  "
                ></hia-icon>
              </div>
            </div> </transition-group
        ></template>
      </Accordion>
    </div>
    <button
      class="side-notifications__dismiss"
      v-show="notifications.length"
      @click="useLocalNotifications().clearNotification()"
    >
      Dismiss all
    </button>
  </div>
</template>

<script lang="ts">
export default {
  name: "side-notifications",
};
</script>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import Accordion from "../theme/Accordion.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  notifications,
  useLocalNotifications,
} from "@/composables/notificationsLocal";

dayjs.extend(relativeTime);
onMounted(() => {
  useLocalNotifications().getNotifications();
});

const isNewNotification = (time: any) => {
  const fiveMinutesAgo = dayjs().subtract(1, "day");
  return dayjs(time).isAfter(fiveMinutesAgo);
};

const newNotifications = computed(() =>
  notifications.value.filter((notification) =>
    isNewNotification(notification.time)
  )
);
const olderNotifications = computed(() =>
  notifications.value.filter(
    (notification) => !isNewNotification(notification.time)
  )
);
</script>

<style scoped lang="scss">
.side-notifications {
  position: absolute;
  z-index: 99;
  right: 8px;
  bottom: 1.5rem;
  width: 380px;
  border-radius: 15px 15px 0 0;
  @apply flex
    flex-col
    mb-2
    bg-gray4
    items-center
    cursor-pointer
    hover:bg-gray9
    transition-all
    ease-out
    duration-200
    shadow-[0_0_3px_var(--gray16)];

  &__header {
    border-radius: 10px 10px 0 0;
    @apply w-full
    flex
    bg-primary
    text-white
    justify-between
    p-2;
  }
  &__body {
    @apply max-h-80
    overflow-y-auto
    overflow-x-hidden
    w-full;
  }
  &__content {
    @apply flex
    flex-row
    w-full
    justify-evenly
    border-b
    border-gray79
    duration-200
    ease-out
    transition
    transform
    py-3;
    & p {
    }
    & img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: top left;
      @apply px-2;
    }
  }
  &__notification {
    @apply flex
    flex-col
    pl-2
    basis-2/3;
  }
  &__time {
    @apply flex
    self-start;
    font-size: 12px;

    & p {
      @apply text-gray35 pr-2;
    }
  }
  &__buttons {
    text-decoration: underline;
    font-size: 12px;
    @apply flex
    text-blue45
    gap-2
    self-end;
  }
  &__dismiss,
  &__empty {
    @apply text-gray74
    border-b
    border-gray79
    w-full;
    text-decoration: underline;
    padding: 2rem;
  }
  &__empty {
    cursor: default;
    width: 100%;
    text-align: center;
    text-decoration: none;
  }
}
.slide-leave,
.leave {
  opacity: 0 !important;
  transform: translateX(50px);
}
.slide-leave-active {
  display: none;
}
:deep(.accordion-header) {
  @apply bg-gray16;
  font-weight: 300;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  text-transform: uppercase;
}
.noView {
  padding: 0 0 0 2rem;
}
</style>
