import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { onBeforeRouteLeave } from "vue-router";

export const useShortcut = () => {
  interface ShortcutItem {
    id: string;
    comboKeys: Array<string | number>;
    action: () => void;
  }

  let shortcutList: Array<ShortcutItem> = [];
  let keysPresseds: Array<number> = [];
  let initialized = false;

  function add(
    comboKeys: Array<string | number>,
    action: (id?: string) => void
  ) {
    if (!initialized) init();
    const shortcut: ShortcutItem = {
      id: uuidv4(),
      comboKeys,
      action,
    };
    shortcutList.push(shortcut);
    return shortcut.id;
  }

  function shortcutListener(remove = false) {
    const evt = remove ? "removeEventListener" : "addEventListener";
    const handleEvent = (event: string, act: (evt: any) => void) =>
      window[evt](
        event,
        function (_event: any) {
          act(_event);
        },
        { passive: false }
      );

    handleEvent("keyup", releaseKeysPressed);
    handleEvent("focusout", cleanKeysPresseds);
    handleEvent("keydown", triggerShortcutAction);
    handleEvent("mousewheel", triggerShortcutAction);
  }

  function init() {
    shortcutListener();
    initialized = true;
  }

  function cleanKeysPresseds() {
    keysPresseds = [];
  }

  function releaseKeysPressed(evt: any) {
    const eventKey = evt.keyCode;
    keysPresseds = _.filter(keysPresseds, (key: number) => key !== eventKey);
  }

  function triggerShortcutAction(evt: any) {
    const eventKey = evt.keyCode ? evt.keyCode : null;
    const wheelDirection = evt.wheelDelta > 0 ? "wheelUp" : "wheelDown";

    if (eventKey && !keysPresseds.includes(eventKey)) {
      keysPresseds.push(eventKey);
    }

    _.forEach(shortcutList, (item: ShortcutItem) => {
      const isWheelEvent = item.comboKeys.includes(wheelDirection);

      if (JSON.stringify(item.comboKeys) === JSON.stringify(keysPresseds)) {
        evt.preventDefault();
        item.action();
      }

      if (evt.type === "mousewheel" && wheelDirection && isWheelEvent) {
        const comboSpecialKeys = _.filter(item.comboKeys, (key) =>
          Number.isInteger(key)
        );

        if (JSON.stringify(comboSpecialKeys) === JSON.stringify(keysPresseds)) {
          evt.preventDefault();
          item.action();
        }
      }
    });
  }

  function removeShortcuts() {
    shortcutListener(true);
    shortcutList = [];
    keysPresseds = [];
    initialized = false;
  }

  function removeShortcutById(id: string) {
    shortcutList = _.filter(shortcutList, (shortcut) => shortcut.id !== id);
  }

  onBeforeRouteLeave(() => {
    removeShortcuts();
  });

  return {
    add,
    removeShortcutById,
    removeShortcuts,
  };
};
