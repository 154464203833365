<template>
  <button
    @click="toggleAccordion()"
    :aria-expanded="isOpen"
    aria-controls="collapse"
    class="accordion-header"
  >
    <slot name="title" />

    <svg
      class="colappse-arrow"
      :class="{
        'rotate-180': isOpen,
        'rotate-0': !isOpen,
      }"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 10"
      aria-hidden="true"
    >
      <path
        d="M15 1.2l-7 7-7-7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>

  <div v-show="isOpen" id="collapse">
    <slot name="content" />
  </div>
</template>

<script lang="ts">
export default {
  name: "app-accordion",
};
</script>

<script setup lang="ts">
import { ref } from "vue";

const isOpen = ref(true);

const toggleAccordion = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped lang="scss">
.accordion-header {
  @apply w-full flex items-center space-x-3 justify-between;
}
.colappse-arrow {
  @apply w-4 transition-all duration-200 transform;
}
</style>
