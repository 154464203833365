import _ from "lodash";
import { ModuleTextToSpeechLayerItem } from "@/models/module";
import {
  SlideMediaTriggerPutPost,
  SlideAnimationTriggerPutPost,
  SlidePronunciationPutPost,
  SlidePauseListPutPost,
  SlideIntroPutPost,
  SlideOutroPutPost,
  SlideSSMLPutPost,
  SlideBasePutPost,
  SlideBase,
  SlideIntro,
  SlideOutro,
  SlidePauses,
  SlideBaseProps,
  SlidePronunciations,
  SlideMediaTrigger,
  SlideAnimationTrigger,
} from "@/models/slide";
import { useAsset } from "./asset";
import { useTextToSpeech } from "./textToSpeech";

export const useSlideUtils = () => {
  const assetComposable = useAsset();
  const textToSpeechComposable = useTextToSpeech();

  function createBaseObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>,
    wordIndexes?: Array<string>
  ) {
    const slideBaseObject: SlideBasePutPost = {
      animationTrigger: createAnimationTriggerObject(value, layers),
      mediaTrigger: createMediaTriggerObject(value, layers),
      ssml: createSSMLObject(value, layers, wordIndexes),
    };

    return slideBaseObject;
  }

  function createBaseResponseObject() {
    const SlideBaseResponseObject: SlideBase = {
      animationTrigger: {
        animation: null,
        htmlContent_Animation: "",
      },
      mediaTrigger: {
        htmlContent_Media: "",
        media: null,
      },
      ssml: {
        htmlContent: "",
        htmlContent_Pauses: "",
        pauses: null,
        pronunciations: null,
        ssmL_Answer: "",
      },
      _layers: [],
    };

    return SlideBaseResponseObject;
  }

  function createIntroResponseObject() {
    const slideIntro: SlideIntro = {
      line_chr: "",
      ...createBaseResponseObject(),
    };
    return slideIntro;
  }

  function createOutroResponseObject() {
    const slideIntro: SlideOutro = {
      outroLine_chr: "",
      ...createBaseResponseObject(),
    };
    return slideIntro;
  }

  function createAnimationTriggerObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const animationTrigger: SlideAnimationTriggerPutPost = {
      HTML_Content_Animation: value ? value : "",
      animationName: [],
      animationSpanId: [],
      animationTimingOffset: [],
      animationTriggerType: [],
      animationUrl: [],
      animationWord: [],
      animationWordIndex: [],
    };

    if (layers && layers.length) {
      _.forEach(layers, (layer) => {
        if (!layer.meta.animation) return;
        animationTrigger.animationName.push(layer.meta.animation.name);
        animationTrigger.animationSpanId.push("");
        animationTrigger.animationTimingOffset.push(0);
        animationTrigger.animationTriggerType.push("video");
        animationTrigger.animationUrl.push("");
        animationTrigger.animationWord.push(layer.word);
        animationTrigger.animationWordIndex.push(layer.wordIndex);
      });
    }

    return animationTrigger;
  }

  function createMediaTriggerObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const mediaTrigger: SlideMediaTriggerPutPost = {
      HTML_Content_Media: value ? value : "",
      mediaFullScr: [],
      mediaId: [],
      mediaLink: [],
      mediaUrl: [],
      mediaStreamingURL: [],
      timingOffset: [],
      triggerType: [],
      word: [],
      wordIndex: [],
    };

    if (layers && layers.length) {
      _.forEach(layers, (layer) => {
        if (!layer.meta.media) return;
        mediaTrigger.mediaFullScr.push(layer.meta.media.fullscreen);
        mediaTrigger.mediaId.push("");
        mediaTrigger.mediaLink.push(layer.meta.media.link);
        mediaTrigger.mediaUrl.push(layer.meta.media.url);
        mediaTrigger.mediaStreamingURL.push(
          layer.meta.media.mediaStreamingUrl || ""
        );
        mediaTrigger.timingOffset.push(0);
        mediaTrigger.triggerType.push(layer.meta.media.type);
        mediaTrigger.word.push(layer.word);
        mediaTrigger.wordIndex.push(layer.wordIndex);
      });
    }

    return mediaTrigger;
  }

  function createSSMLObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>,
    wordIndexes?: Array<string>
  ) {
    const pronunciation = createPronunciationObject(layers);
    const pauseList = createPauseListObject(layers);

    const ssml: SlideSSMLPutPost = {
      HtmlContent: value ? value : "",
      HtmlContent_Pause: value ? value : "",
      SSML_Tag_Pause: pauseList.ssmL_Tag_Pause,
      SSML_Tag_Pronunciation: pronunciation.SSML_Tag_Pronunciation,
      PhonemeType_Pronunciation: pronunciation.PhonemeType_Pronunciation,
      Utterances: pronunciation.utterances,
      Utterances_Pauses: pauseList.utterances_Pauses,
      actualWordIds: pauseList.actualWordIds,
      pauseTimeAfter: pauseList.pauseTimeAfter,
      pauseTimeBefore: pauseList.pauseTimeBefore,
      pausesSpanTags: pauseList.pausesSpanTags,
      pausesWordIndexes: pauseList.pausesWordIndexes,
      pronunciation: pronunciation.pronunciation,
      wordIds: pauseList.wordIds,
      wordIndexes: wordIndexes || [],
    };

    return ssml;
  }

  function createPronunciationObject(
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const pronunciationObject: SlidePronunciationPutPost = {
      pronunciation: [],
      SSML_Tag_Pronunciation: [],
      utterances: [],
      PhonemeType_Pronunciation: [],
    };

    if (layers) {
      _.forEach(layers, (pronunciation) => {
        if (
          !pronunciationObject.utterances.includes(pronunciation.word) &&
          pronunciation.meta.pronunciation
        ) {
          pronunciationObject.pronunciation.push(
            pronunciation.meta.pronunciation.value
          );
          pronunciationObject.utterances.push(pronunciation.word);
          pronunciationObject.SSML_Tag_Pronunciation.push(
            pronunciation.meta.pronunciation.phonemeType
              ? textToSpeechComposable.generateSsmlPhonemeTag(
                  pronunciation.word,
                  pronunciation.meta.pronunciation.value,
                  pronunciation.meta.pronunciation.phonemeType
                )
              : textToSpeechComposable.generateSsmlSubTag(
                  pronunciation.word,
                  pronunciation.meta.pronunciation.value
                )
          );
          pronunciationObject.PhonemeType_Pronunciation.push(
            pronunciation.meta.pronunciation.phonemeType
          );
        }
      });
    }

    return pronunciationObject;
  }

  function createPauseListObject(layers?: Array<ModuleTextToSpeechLayerItem>) {
    const pauseList: SlidePauseListPutPost = {
      actualWordIds: [],
      pauseTimeAfter: [],
      pauseTimeBefore: [],
      pausesSpanTags: [],
      pausesWordIndexes: [],
      ssmL_Tag_Pause: [],
      utterances_Pauses: [],
      wordIds: [],
    };

    if (layers) {
      _.forEach(layers, (pause) => {
        if (!pause.meta.pause) return;
        pauseList.actualWordIds.push(pause.wordIndex.toString());
        pauseList.pauseTimeAfter.push(pause.meta.pause.durationAfter);
        pauseList.pauseTimeBefore.push(pause.meta.pause.durationBefore);
        pauseList.pausesSpanTags.push("");
        pauseList.pausesWordIndexes.push({
          wordIndex: pause.wordIndex,
          wordId: "",
        });
        pauseList.ssmL_Tag_Pause.push(
          `<break time='${pause.meta.pause.durationBefore}ms'/>${pause.word}<break time='${pause.meta.pause.durationAfter}ms'/>`
        );
        pauseList.utterances_Pauses.push(pause.word);
        pauseList.wordIds.push("");
      });
    }

    return pauseList;
  }

  function createIntroObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>,
    wordIndexes?: Array<string>
  ) {
    const intro: SlideIntroPutPost = {
      introLine_chr: value ? value : "",
      ...createBaseObject(value, layers, wordIndexes),
    };
    return intro;
  }

  function createOutroObject(
    value?: string,
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const outro: SlideOutroPutPost = {
      outroLine_chr: value ? value : "",
      ...createBaseObject(value, layers, []),
    };
    return outro;
  }

  function createModuleLayersObject(text: string, slide: SlideBaseProps) {
    return [
      ...createMediaLayersObject(slide),
      ...createAnimationLayersObject(slide),
      ...createPronunciationlayersObject(text, slide),
      ...createPauselayersObject(slide),
    ];
  }

  function createPauselayersObject(slide: SlideBaseProps) {
    const pauseLayers: Array<ModuleTextToSpeechLayerItem> = [];

    if (slide.ssml) {
      let htmlContenPauses: HTMLElement | null = null;
      const findWordIndexFromInHTML = (wordId: string) => {
        if (!htmlContenPauses) {
          htmlContenPauses = document.createElement("div");
          htmlContenPauses.innerHTML = slide.ssml.htmlContent_Pauses;
        }

        let sumWordIndex = 0;
        let wordIndex = 0;

        _.forEach(htmlContenPauses.childNodes, (el) => {
          const element = el as HTMLElement;
          if (!element || !element.textContent) return;
          sumWordIndex += _.filter(
            element.textContent.split(" "),
            (val) => !!val
          ).length;
          if (element.id === wordId) wordIndex = sumWordIndex;
        });

        return wordIndex - 1;
      };

      if (slide.ssml.pauses) {
        _.forEach(slide.ssml.pauses, (pause) => {
          let wordIndex = pause.wordId;
          if (isNaN(Number(wordIndex)) && typeof wordIndex === "string") {
            wordIndex = findWordIndexFromInHTML(wordIndex);
          }
          pauseLayers.push({
            word: pause.utteranceText,
            wordIndex: +wordIndex,
            type: "pause",
            meta: {
              pause: {
                durationAfter: +pause.pauseTimeAfter,
                durationBefore: +pause.pauseTimeBefore,
              },
            },
          });
        });
      }
    }

    return pauseLayers;
  }

  function createPronunciationlayersObject(
    text: string,
    slide: SlideBaseProps
  ) {
    const pronunciationLayers: Array<ModuleTextToSpeechLayerItem> = [];
    if (text && slide.ssml && slide.ssml.pronunciations) {
      _.forEach(slide.ssml.pronunciations, (pronunciation) => {
        const addedWords = _.map(
          pronunciationLayers,
          (pronunciation) => pronunciation.word
        );

        if (addedWords.includes(pronunciation.utteranceText)) return;

        pronunciationLayers.push({
          word: pronunciation.utteranceText,
          wordIndex: 0,
          type: "pronunciation",
          meta: {
            pronunciation: {
              value: pronunciation.pronunciation,
              phonemeType: pronunciation.phonemeType,
            },
          },
        });
      });
    }
    return pronunciationLayers;
  }

  function createAnimationLayersObject(slide: SlideBaseProps) {
    const animationsLayers: Array<ModuleTextToSpeechLayerItem> = [];
    if (slide.animationTrigger && slide.animationTrigger.animation) {
      _.forEach(slide.animationTrigger.animation, (animation) => {
        animationsLayers.push({
          word: animation.animationWord,
          wordIndex: animation.animationWordIndex,
          type: "animation",
          meta: {
            animation: {
              name: animation.animationName,
              duration: 0,
              timelineDuration: 0,
              offset: 0,
            },
          },
        });
      });
    }
    return animationsLayers;
  }

  function createMediaLayersObject(slide: SlideBaseProps) {
    const mediaLayers: Array<ModuleTextToSpeechLayerItem> = [];
    if (slide.mediaTrigger && slide.mediaTrigger.media) {
      _.forEach(slide.mediaTrigger.media, (media) => {
        const galleryAsset = createMediaAssetObject(media.mediaURL);
        if (galleryAsset) {
          mediaLayers.push({
            word: media.word,
            wordIndex: media.wordIndex,
            type: "media",
            meta: {
              media: {
                ...galleryAsset,
                ...{
                  timelineDuration: 0,
                  autoplay: false,
                  fullscreen: media.mediaFullScr,
                  link: media.mediaLink,
                },
              },
            },
          });
        }
      });
    }

    return mediaLayers;
  }

  function createMediaAssetObject(url: string) {
    if (!url) return null;
    const galleryAsset = assetComposable.getCachedFile(url);
    if (!galleryAsset) return null;
    return {
      ...galleryAsset,
      timelineDuration: galleryAsset.duration,
    };
  }

  function createPauseResponseObject(
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const pauses: Array<SlidePauses> = [];

    if (layers) {
      _.forEach(layers, (pause) => {
        if (!pause.meta.pause) return;
        const { durationAfter, durationBefore } = pause.meta.pause;
        const pauseList: SlidePauses = {
          utteranceText: pause.word,
          ssmL_Tag: `<break time='${durationBefore}ms'/>${pause.word}<break time='${durationAfter}ms'/>`,
          pauseTimeAfter: durationAfter.toString(),
          pauseTimeBefore: durationBefore.toString(),
          wordId: pause.wordIndex.toString(),
          spanTag: "",
        };

        pauses.push(pauseList);
      });
    }

    return pauses;
  }

  function createPronunciationResponseObject(
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const pronunciations: Array<SlidePronunciations> = [];

    if (layers) {
      _.forEach(layers, (pronunciation) => {
        const pronunciationObject: SlidePronunciations = {
          utteranceText: "",
          pronunciation: "",
          ssmL_Tag: "",
          phonemeType: null,
        };
        if (!pronunciation.meta.pronunciation) return;
        pronunciationObject.ssmL_Tag = `<sub alias='${pronunciation.meta.pronunciation.value}'>${pronunciation.word}</sub>`;
        pronunciationObject.utteranceText = pronunciation.word;
        pronunciationObject.pronunciation =
          pronunciation.meta.pronunciation.value;
        pronunciationObject.phonemeType =
          pronunciation.meta.pronunciation.phonemeType;
        pronunciations.push(pronunciationObject);
      });
    }

    return pronunciations;
  }

  function createMediaTriggerResponseObject(
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const medias: Array<SlideMediaTrigger> = [];

    if (layers && layers.length) {
      _.forEach(layers, (layer) => {
        if (!layer.meta.media) return;
        const mediaTrigger: SlideMediaTrigger = {
          triggerType: layer.meta.media.type,
          wordIndex: layer.wordIndex,
          word: layer.word,
          timingOffset: 0,
          mediaURL: layer.meta.media.url,
          mediaStreamingURL: layer.meta.media.mediaStreamingUrl || "",
          mediaLink: layer.meta.media.url,
          mediaFullScr: true,
          mediaId: "",
        };

        medias.push(mediaTrigger);
      });
    }

    return medias;
  }

  function createAnimationTriggerResponseObject(
    layers?: Array<ModuleTextToSpeechLayerItem>
  ) {
    const animations: Array<SlideAnimationTrigger> = [];

    if (layers && layers.length) {
      _.forEach(layers, (layer) => {
        if (!layer.meta.animation) return;

        const animationTrigger: SlideAnimationTrigger = {
          animationTriggerType: "video",
          animationWordIndex: layer.wordIndex,
          animationWord: layer.word,
          animationTimingOffset: 0,
          animationName: layer.meta.animation.name,
          animationURL: "",
          animationSpanId: "",
        };

        animations.push(animationTrigger);
      });
    }

    return animations;
  }

  return {
    createBaseObject,
    createIntroObject,
    createAnimationTriggerObject,
    createMediaTriggerObject,
    createPauseListObject,
    createSSMLObject,
    createOutroObject,
    createPronunciationObject,
    createIntroResponseObject,
    createOutroResponseObject,
    createBaseResponseObject,
    createModuleLayersObject,
    createPauseResponseObject,
    createPronunciationResponseObject,
    createMediaTriggerResponseObject,
    createAnimationTriggerResponseObject,
  };
};
